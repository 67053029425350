import React from 'react';

const DashBoardItem = ({ icon, title, data }) => {
    return (
            <div style={{width: 450, maxHeight: 300, marginBottom: 10, marginRight: 20, marginLeft: 20}} className="card border-warning shadow-sm ">
                <div className="card-body">
                    <div className="row">
                        <div className="col-5">
                            <div className="icon-big text-center icon-warning">
                                {icon}
                            </div>
                        </div>
                        <div className="col-7">
                            <div className="numbers">
                                <p className="card-category">{title}</p>
                                <h5 className="card-title">{data}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default DashBoardItem;