import React, { useState } from "react";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { corporativePaymentWeb, calcularViajePorHora } from "../../../utils/petitions";
import Button from 'react-bootstrap/Button';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getHoursFromSecs } from "../../../utils/others"
import moment from "moment";
import HiddenAlert from '../../components/HiddenAlert';

import es from "date-fns/locale/es";

registerLocale("es", es);

const PagarSolicitudForm = (props) => {
    const [fechaFin, setFechaFin] = useState(new Date());
    const [cedula, setCedula] = useState("");
    const [monto, setMonto] = useState("");
    const [toggleDisabledButton, setToggleDisabledButton] = useState(true);
    const [toggleDisableButtonPay, setToggleDisableButtonPay] = useState(false);


    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [paymentSuccessMessage, setPaymentSuccessMessage] = useState("");

    const [paymentError, setPaymentError] = useState(false);
    const [paymentErrorMessage, setPaymentErrorMessage] = useState("");

    const [alertError, setAlertError] = useState(false);
    const [alertErrorMessage, setAlertErrorMessage] = useState("");


    const getTheTimeDifference = () => {
        let newFechaFin = moment(fechaFin);
        let sec = (newFechaFin.diff(props.fechaInicio, 'seconds'));
        let time = getHoursFromSecs(sec);
        return time;
    }

    const getCalculatePrice = async (e) => {
        e.preventDefault();
        setDefaultItems();
        if (props.cedula !== "" && props.pin !== "") { //999999999
            const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
            const response = await calcularViajePorHora(header, cedula, "999999999", props.uuid, getTheTimeDifference());
            if (response.status === 200) {
                if (response.data.Codigo === "1") {
                    setMonto(response.data.Contenido);
                    setToggleDisabledButton(false);
                } else {
                    setAlertError(true);
                    setAlertErrorMessage(response.data.Mensaje);
                }
            }
        }
    }

    const setDefaultItems = () => {
        setAlertError(false);
        setPaymentSuccess(false);
        setPaymentError(false);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await corporativePaymentWeb(header, cedula, "999999999", props.uuid, props.idTaxi, monto);

        if (response.data.Codigo === "1") {
            setPaymentSuccess(true);
            setPaymentSuccessMessage("El pago realizado fue exítoso");
            setToggleDisableButtonPay(true);
            window.setTimeout(() => {
                props.history.push("/solicitudes")
            }, 3000)
        } else {
            setPaymentError(true);
            setPaymentErrorMessage(response.data.Mensaje)
        }
    }

    return (
        <React.Fragment>
            <Card className="mt-5" style={{ backgroundColor: "#FFF", width: "50%", marginBottom: "70px" }}>
                {props.defineForm === "VIAJE_HORA" ?
                    <Card.Body >
                        <Card.Title className="text-center" >Pagar solicitud</Card.Title>
                        <Form onSubmit={getCalculatePrice} className="mt-4">
                            <Form.Group >
                                <Form.Label>Cedula</Form.Label>
                                <Form.Control
                                    onChange={(e) => setCedula(e.target.value)}
                                    value={cedula}
                                    required
                                    disabled={!toggleDisabledButton}
                                    placeholder="Ingrese la cedula" />
                            </Form.Group>
                            <Form.Group >
                                <Form.Label >Fecha fin</Form.Label>
                                <DatePicker
                                    showTimeSelect
                                    placeholderText="Ingrese una fecha"
                                    selected={fechaFin}
                                    onChange={e => setFechaFin(e)}
                                    disabled={!toggleDisabledButton}
                                    dateFormat="dd/MM/yyyy  HH:mm"
                                    locale="es"
                                    timeIntervals={15}
                                />
                            </Form.Group>
                            <Form.Group >
                                <Form.Label >Monto</Form.Label>
                                <Form.Control
                                    value={monto}
                                    requerid
                                    disabled
                                    type="number"
                                    placeholder="Debe calcular el monto" />
                            </Form.Group>
                            {
                                toggleDisabledButton ?
                                    <Button type="submit" className="btn btn-primary w-100">Calcular</Button>
                                    :
                                    <Button onClick={onSubmit} disabled={toggleDisableButtonPay} className="btn btn-primary w-100">Pagar</Button>
                            }
                        </Form>
                    </Card.Body>
                    :
                    <Card.Body >
                        <Card.Title className="text-center" >Pagar solicitud</Card.Title>
                        <Form onSubmit={onSubmit} className="mt-4">
                            <Form.Group >
                                <Form.Label >Cedula</Form.Label>
                                <Form.Control
                                    onChange={(e) => setCedula(e.target.value)}
                                    value={cedula}
                                    required
                                    placeholder="Ingrese la cedula" />
                            </Form.Group>
                            <Form.Group >
                                <Form.Label >Monto</Form.Label>
                                <Form.Control
                                    value={monto}
                                    onChange={e => setMonto(e.target.value)}
                                    required
                                    type="number"
                                    placeholder="Ingrese el monto" />
                            </Form.Group>
                            <Button disabled={toggleDisableButtonPay} type="submit" style={{ alingSelf: "center" }} className="btn btn-primary w-100" >Pagar</Button>
                        </Form>
                    </Card.Body>
                }
            </Card>
            <HiddenAlert visible={alertError} text={alertErrorMessage} variant="danger" />
            <HiddenAlert visible={paymentSuccess} text={paymentSuccessMessage} variant="success" />
            <HiddenAlert visible={paymentError} text={paymentErrorMessage} variant="danger" />
        </React.Fragment>
    )
}

export default PagarSolicitudForm;