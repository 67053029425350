import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from "react-bootstrap/Col";
import { getEmpresas, getDepartamentos, postUsuario } from "../../../utils/petitions";
import Loader from 'react-loader-spinner';
import HiddenAlert from "../../components/HiddenAlert";

class CrearUsuario extends Component {
    state = {
        enterprises: [],
        departments: [],
        departmentsFromEnterprises: [],
        showLoader: false,
        name: "",
        lastname: "",
        identification: "",
        email_voyentaxi: "",
        emailEncargado: "",
        email: "",
        password: "",
        limMonth: "",
        limTravel: "",
        enterprise: "",
        department: "",
        pin: "",
        error: false,
        errorText: "",
    }

    componentDidMount() {
        this.getTheEmpresas();
        this.getTheDepartamentos();
    }

    getTheEmpresas = async () => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getEmpresas(header);
        if (response.status === 200) {
            this.setState({
                enterprises: response.data.empresas,
                loading: false
            })
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    handleSelectBoxEnteprise = (e) => {
        this.setState({
            enterprise: JSON.parse(e.target.value).numero
        })
        this.setDepartmentsFromEnterprise(e.target.value);
    }

    setDepartmentsFromEnterprise = (data) => {
        try {
            let enterprises = JSON.parse(data)
            console.log(enterprises)
            const response = this.state.departments.filter(item => enterprises.departamentos.includes(parseInt(item.id)));
            console.log(response)
            this.setState({
                departmentsFromEnterprises: response
            })
        } catch (e) {
            console.log(e)
        }
    }

    getTheDepartamentos = async () => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getDepartamentos(header);
        if (response.status === 200) {
            this.setState({
                departments: response.data.departamentos,
                loading: false
            })
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    handleInputChange = ({ target: { name, value } }) => {
        this.setState({ [name]: value })
    }

    handleClickButton = async (e) => {
        e.preventDefault();
        this.setDefaultItems();
        this.setState({
            showLoader: true
        })
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const post = {
            usuario: {
                nombre: this.state.name,
                apellido: this.state.lastname,
                email: this.state.email,
                telefono: this.state.emailEncargado,
                ci: this.state.identification.trim(),
                email_voyentaxi: this.state.email_voyentaxi,
                password: this.state.password,
                limite_viaje: this.state.limTravel,
                limitemensual: this.state.limMonth,
                departamento_id: this.state.department,
                num_empresa: this.state.enterprise,
                pin: this.state.pin.trim(),
                montoactual: null,
                fecha_creado: null,
                fecha_modificado: null,
                habilitado: null,
                is_admin: false,
                is_gral: false,
            }
        }
        const response = await postUsuario(header, post);
        if (response) {
            if (response.status === 200) {
                this.props.history.push("/usuarios")
            } else if (response.status === 401) {
                localStorage.clear();
                this.props.history.push("/")
            } else {
                this.setState({
                    error: true,
                    errorText: response.data.errors.msj
                })
            }
            this.setState({ showLoader: false })
        }
    }

    setDefaultItems = () => {
        this.setState({
            showLoader: false,
            error: false,
            errorText: false,

        })
    }

    render() {
        return (
            <div className="container">
                <Form onSubmit={this.handleClickButton}>
                    <div className="d-flex">
                        <h4>Registro de nuevo usuario</h4>
                        <Loader
                            visible={this.state.showLoader}
                            className="ml-3"
                            type="Oval"
                            color="blue"
                            height={25}
                            width={25}
                        />
                    </div>
                    <hr className="mt-2" />
                    <Form.Row>
                        <Form.Group as={Col} >
                            <h6>Nombre *</h6>
                            <Form.Control name="name" onChange={this.handleInputChange} required size="sm" placeholder="Ingrese el nombre del usuario" />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Apellido *</h6>
                            <Form.Control name="lastname" onChange={this.handleInputChange} required size="sm" placeholder="Ingrese el apellido del usuario" />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <h6>Email *</h6>
                            <Form.Control name="email" onChange={this.handleInputChange} required size="sm" placeholder="Ingrese el email del usuario" />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Email encargado</h6>
                            <Form.Control name="emailEncargado" onChange={this.handleInputChange} size="sm" placeholder="Ingrese el email encargado" />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <h6>CI *</h6>
                            <Form.Control name="identification" onChange={this.handleInputChange} required size="sm" placeholder="Ingrese un nro de documento del usuario" />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Email vet</h6>
                            <Form.Control name="email_voyentaxi" onChange={this.handleInputChange} size="sm" placeholder="Ingrese el email vet" />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <h6>Contraseña *</h6>
                            <Form.Control
                                name="password"
                                onChange={this.handleInputChange}
                                type="password"
                                required
                                size="sm"
                                placeholder="Ingrese la contraseña del usuario" />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Pin corporativo</h6>
                            <Form.Control
                                requerid
                                name="pin"
                                onChange={this.handleInputChange}
                                size="sm"
                                placeholder="Ingrese el pin corporativo" />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <h6>Limite mensual *</h6>
                            <Form.Control name="limMonth" onChange={this.handleInputChange} type="number" required size="sm" placeholder="Ingrese el limite mensual en $" />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Limite por viaje *</h6>
                            <Form.Control name="limTravel" onChange={this.handleInputChange} type="number" required size="sm" placeholder="Ingrese el limite por viaje en $" />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <h6>Empresa *</h6>
                            <Form.Control onChange={this.handleSelectBoxEnteprise} required name="enterprise" size="sm" as="select">
                                <option value={""}>Seleccione una empresa</option>
                                {
                                    this.state.enterprises.map(item => (
                                        <option key={item.id} value={JSON.stringify(item)}>{item.nombre}</option>
                                    ))
                                }
                            </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Departamento *</h6>
                            <Form.Control onChange={this.handleInputChange} required name="department" size="sm" as="select">
                                <option value={""}>Seleccione un departamento</option>
                                {
                                    this.state.departmentsFromEnterprises.map(item => (
                                        <option key={item.id} value={item.id}>{item.descripcion}</option>
                                    ))
                                }
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>

                    <Button className="mt-3 mr-3" size="sm" variant="primary" type="submit">
                        Crear Usuario
                    </Button>

                    <Button className="mt-3" size="sm" variant="secondary" onClick={() => this.props.history.push("/usuarios")}>
                        Cancelar
                    </Button>
                </Form>
                <HiddenAlert visible={this.state.error} text={this.state.errorText} variant="danger" />
            </div>
        );
    }
}

export default CrearUsuario;