import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { getSolicitud } from "../../../utils/petitions";
import { FaUserAlt, FaTaxi } from "react-icons/fa";
import Loader from 'react-loader-spinner';
import { MapContainer, TileLayer, Marker, Polyline } from "react-leaflet";
import StarRatings from 'react-star-ratings';
import { renderToStaticMarkup } from 'react-dom/server';
import { divIcon } from 'leaflet';
import polyline from "@mapbox/polyline";
import moment from "moment";

function SolicitudInformation(props) {
    return (
        <Table size="sm" className="mt-3" striped bordered hover>
            <tbody>
                <tr>
                    <td className="font-weight-bold" colSpan="3" style={{ backgroundColor: "white", borderColor: "#CDCDCD" }}> Datos de solicitud</td>
                    <td style={{ backgroundColor: "white", borderColor: "#CDCDCD" }} colSpan="2">
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <StarRatings
                                rating={props.ranking}
                                starRatedColor="black"
                                numberOfStars={5}
                                starDimension="25px"
                                starSpacing="5px"
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="font-weight-bold" >Dirección:</td>
                    <td colSpan="3">{props.direccion} {props.puerta}
                        <br></br>

                        <p style={{ fontStyle: "italic", color: "#7A7A7A" }}>Referencia: {props.refOrig}</p>
                    </td>
                </tr>
                <tr>
                    <td className="font-weight-bold" >Dirección de pago:</td>
                    <td >{props.direccionPago}</td>
                    <td className="font-weight-bold">Fecha de inicio:</td>
                    <td>{(props.fechaInicio)}</td>
                </tr>
                <tr>
                    <td className="font-weight-bold">Estado:</td>
                    <td>ViajeIniciado</td>
                    <td className="font-weight-bold">Fecha de fin:</td>
                    <td>{(props.fechaFin)}</td>
                </tr>
                <tr>
                    <td className="font-weight-bold">Referencia destino:</td>
                    <td>{props.refDest}</td>
                    <td className="font-weight-bold">Tiempo estimado llegada:</td>
                    <td>{props.tiempoTax}</td>
                </tr>
                <tr>
                    <td className="font-weight-bold">Fecha alta:</td>
                    <td>{moment(props.fechaalta).zone(0).format('DD-MM-YYYY HH:mm:ss')}</td>
                    <td className="font-weight-bold">Duración:</td>
                    <td>{props.duracion}</td>
                </tr>
                <tr>
                    <td className="font-weight-bold">Id:</td>
                    <td>{props.id}</td>
                    <td className="font-weight-bold">Monto:</td>
                    <td>{props.monto} $</td>
                </tr>
                <tr>
                    <td className="font-weight-bold">Nro transacción:</td>
                    <td>{props.transaccion}</td>
                </tr>
            </tbody>
        </Table>
    )
}

const MapComponent = (props) => {
    const styleMap = { "width": "100%", "height": "60vh" }
    const iconMarkupOrigin = renderToStaticMarkup(<i style={{ color: "#46DD66" }} className=" fa fa-map-marker-alt fa-3x" />);
    const iconMarkupDest = renderToStaticMarkup(<i style={{ color: "#9319DC" }} className=" fa fa-map-marker-alt fa-3x" />);
    const iconOrigin = divIcon({
        html: iconMarkupOrigin,
        className: "dummy",
        iconSize: [24, 36],
        iconAnchor: [12, 36]
    });

    const iconDest = divIcon({
        html: iconMarkupDest,
        className: "dummy",
        iconSize: [24, 36],
        iconAnchor: [12, 36]

    });

    const bounds = [[props.latitudOrigen, props.longitudOrigen], [props.latPago, props.lngPago]]
    return (
        <div style={{ height: '60vh', width: '100' }}>
            <MapContainer
                style={styleMap}
                bounds={bounds}
            >
                <Marker icon={iconOrigin} position={[props.latitudOrigen, props.longitudOrigen]}></Marker>
                {
                    props.latPago != null && props.lngPago != null ?
                        <Marker icon={iconDest} position={[props.latPago, props.lngPago]}></Marker>
                        : null
                }

                <Polyline pathOptions={{ color: 'blue' }} positions={props.pathTaxi} />
                <Polyline pathOptions={{ color: 'red' }} positions={props.path} />
                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                />
            </MapContainer>
        </div >
    )
}

function PasajeroInformation(props) {
    return (
        <Table size="sm" striped bordered hover className="mr-5">
            <tbody>
                <tr>
                    <td colSpan="2" className="font-weight-bold bordered" style={{ backgroundColor: "white", borderColor: "#CDCDCD" }}> <FaUserAlt className="mb-1 mr-1 ml-1" /> Datos pasajero</td>
                </tr>
                <tr>
                    <td className="font-weight-bold">Usuario:</td>
                    <td>{props.usuarionombre} {props.usuarioapellido}</td>
                </tr>
                <tr>
                    <td className="font-weight-bold">Cédula:</td>
                    <td>{props.cedulaPasajero}</td>
                </tr>
                <tr>
                    <td className="font-weight-bold">Departamento:</td>
                    <td>{props.usuariodepartamento}</td>
                </tr>
                <tr>
                    <td className="font-weight-bold" colSpan="2">Comentario:</td>
                </tr>
                {props.comentarioPasajero !== "" ?
                    <tr>
                        <td colSpan="2">{props.comentarioPasajero}</td>
                    </tr>
                    :
                    <tr>
                        <td colSpan="2">Sin comentario</td>
                    </tr>

                }

            </tbody>
        </Table>
    )
}

function TaxInformation(props) {
    return (
        <Table size="sm" striped bordered hover>
            <tbody>
                <tr>
                    <td colSpan="2" className="font-weight-bold" style={{ backgroundColor: "white", borderColor: "#CDCDCD", height: 30}}><FaTaxi className="mb-1 mr-1 ml-1" /> Datos taxi</td>
                </tr>
                <tr>
                    <td className="font-weight-bold">Numero taxi:</td>
                    <td>{props.numeroTaxi}</td>
                </tr>
            </tbody>
        </Table>
    )
}

function Solicitud(props) {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pathTaxi, setPathTaxi] = useState("");
    const [path, setPath] = useState("");

    useEffect(() => {
        const getTheSolicitud = async () => {
            const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
            const response = await getSolicitud(header, props.match.params.id);
            if (response.status === 200) {
                console.log(response.data)
                setData(response.data.solicitude);
                setLoading(false);
                if (response.data.solicitude.recorridotaxi !== null) {
                    const polyLine = polyline.decode(decodeURIComponent(response.data.solicitude.recorridotaxi));
                    setPathTaxi(polyLine);
                }
                if (response.data.solicitude.recorrido !== null) {
                    const polyLine = polyline.decode(response.data.solicitude.recorrido);
                    setPath(polyLine);
                }
            } else if (response.status === 401) {
                localStorage.clear();
                this.props.history.push("/")
            }
        }
        getTheSolicitud();
    }, [props.match.params.id])

    return (
        <div className="container">
            {
                loading ?
                    <div>
                        <Loader
                            className="centered"
                            type="Circles"
                            color="#000"
                            visible={loading}
                            height={50}
                            width={50}
                        />
                        <h6 className="centeredTwo">Cargando...</h6>
                    </div>
                    :
                    <div>
                        <div className="d-flex">
                            <h4>Solicitud</h4>
                            <p style={{ fontSize: 12, color: "gray" }} className="mt-2 ml-2">UUID: {data.uuid}</p>
                        </div>
                        <hr className="mt-1" />
                        <MapComponent
                            latitudOrigen={data.latitudorigen}
                            longitudOrigen={data.longitudorigen}
                            latPago={data.latpago}
                            lngPago={data.lngpago}
                            pathTaxi={pathTaxi}
                            path={path}
                        />
                        <SolicitudInformation
                            direccion={data.direccion}
                            puerta={data.numeropuerta}
                            estado={data.estado}
                            canal={data.canal}
                            refOrig={data.referenciaorigen}
                            refDest={data.referenciadestino}
                            tiempoTax={data.tiempoestimadollegadataxi}
                            fechaalta={data.fechaalta}
                            monto={data.monto}
                            id={data.id}
                            uuid={data.uuid}
                            duracion={data.duracion}
                            transaccion={data.transaccion}
                            direccionPago={data.direccionpago}
                            fechaPago={data.fechapago}
                            fechaInicio={data.fechainicio}
                            fechaFin={data.fechafin}
                        />
                        <div className="d-flex justify-content-between">
                            <PasajeroInformation
                                usuarionombre={data.usuarionombre}
                                usuarioapellido={data.usuarioapellido}
                                cedulaPasajero={data.usuarioci}
                                usuariodepartamento={data.usuariodepartamento}
                                comentarioPasajero={data.comentariopasajero}
                            />
                            <TaxInformation
                                numeroTaxi={data.numerotaxi}
                            />
                        </div>
                    </div>
            }

        </div>
    );
}

export default Solicitud;