export const empresas = [
    {
        Numero: "1",
        Nombre: "RT 141 CON POS",
    },
    {
        Numero: "2",
        Nombre: "Radio Celeritas"
    },
    {
        Numero: "3",
        Nombre: "Radio Taxi Punta Gorda"
    },
    {
        Numero: "4",
        Nombre: "RadioRivera"
    },
    {
        Numero: "5",
        Nombre: "Conductores"
    },
    {
        Numero: "6",
        Nombre: "YaColon"
    },
    {
        Numero: "7",
        Nombre: "Scot"
    },
    {
        Numero: "8",
        Nombre: "FonoTaxi"
    },
    {
        Numero: "99",
        Nombre: "MontevideoCoperativo"
    },
    {
        Numero: "10",
        Nombre: "TaxiLaPaloma"
    },
    {
        Numero: "11",
        Nombre: "Asociación de taxis treinta y tres"
    },
    {
        Numero: "12",
        Nombre: "Radio Taxi 141"
    },
    {
        Numero: "13",
        Nombre: "San Jose"
    },
    {
        Numero: "14",
        Nombre: "Santa Lucia"
    },
    {
        Numero: "3143",
        Nombre: "Test Cpatu"
    },
    {
        Numero: "16",
        Nombre: "Fray Bentos"
    },
    {
        Numero: "2018",
        Nombre: "Corporativo"
    },
    {
        Numero: "3144",
        Nombre: "Prueba gobernanza"
    }
]

export const estadosSolicitud = [{
    id: "0",
    nombre: "Pendiente",
},
{
    id: "1",
    nombre: "Rechazada",
},
{
    id: "2",
    nombre: "Confirmada"
},
{
    id: "3",
    nombre: "CanceladaTaxi",
},
{
    id: "4",
    nombre: "CanceladaPasajero",
},
{
    id: "5",
    nombre: "Viaje iniciado"
},
{
    id: "6",
    nombre: "Por defecto"
},
{
    id: "7",
    nombre: "ViajeFinalizado"
},
{
    id: "8",
    nombre: "No hay taxis cerca"
},
{
    id: "9",
    nombre: "Error"
},
{
    id: "10",
    nombre: "Redespacho"
},
{
    id: "11",
    nombre: "ErrorPasajero"
},
{
    id: "12",
    nombre: "Abandono"
}]

export const getHoursFromSecs = (segundos) => {
    return segundos / 3600
}