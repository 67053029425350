import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from "react-bootstrap/Col";
import { getEmpresas, getDepartamentos, getAdmin, putAdmin } from "../../../utils/petitions";
import Loader from 'react-loader-spinner';

class EditarAdministrador extends Component {
    state = {
        enterprises: [],
        departments: [],
        showLoader: false,
        ci: "",
        name: "",
        email: "",
        lastName: "",
        password: "",
        tel: "",
        limMonth: "",
        limTravel: "",
        isGeneral: "",
        isEnable: "",

        enterprise: "",
        department: "",
    }

    componentDidMount() {
        this.getTheEmpresas();
        this.getTheDepartamentos();
        this.getTheAdmin();
    }

    handleInputChange = ({ target: { name, value } }) => {
        this.setState({ [name]: value })
    }

    handleClickButton = (e) => {
        e.preventDefault();
        this.putTheAdmin();
    }

    putTheAdmin = async () => {
        this.setState({
            showLoader: true
        })
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const put = {
            admin: {
                ci: this.state.ci,
                nombre: this.state.name,
                apellido: this.state.lastName,
                email: this.state.email,
                telefono: this.state.tel,
                password: this.state.password,
                limitemensual: this.state.limMonth,
                limite_viaje: this.state.limMonth,
                is_gral: this.state.isGeneral ? "1" : "0",
                empresa_id: this.state.enterprise,
                departamento_id: this.state.department,
                habilitado: this.state.isEnable ? "1" : "0",
                menu: "administrar"
            }
        }
        const response = await putAdmin(header, this.props.match.params.id, put)
        if (response.status === 200) {
            this.props.history.push("/admins")
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }

    }

    getTheAdmin = async () => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getAdmin(header, this.props.match.params.id);
        console.log(response)
        if (response.status === 200) {
            this.setState({
                ci: response.data.admin.ci,
                name: response.data.admin.nombre,
                lastName: response.data.admin.apellido,
                email: response.data.admin.email,
                limMonth: response.data.admin.limitemensual,
                limTravel: response.data.admin.limite_viaje,
                tel: response.data.admin.telefono,
                isGeneral: response.data.admin.is_gral === 1 ? true : false,
                isEnable: response.data.admin.habilitado === 1 ? true : false,
                department: response.data.admin.departamento_id,
                enterprise: response.data.admin.empresa_id
            })
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    handleCheckBox = (e) => {
        this.setState({
            isGeneral: e.target.checked
        })
    }

    handleCheckBoxEnable = (e) => {
        this.setState({
            isEnable: e.target.checked
        })
    }

    getTheEmpresas = async () => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getEmpresas(header);
        if (response.status === 200) {
            this.setState({
                enterprises: response.data.empresas,
                loading: false
            })
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    getTheDepartamentos = async () => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getDepartamentos(header);
        if (response.status === 200) {
            this.setState({
                departments: response.data.departamentos,
                loading: false
            })
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    render() {
        return (
            <div className="container">
                <Form onSubmit={this.handleClickButton}>
                    <div className="d-flex">
                        <h4>Editar administrador</h4>
                        <Loader
                            visible={this.state.showLoader}
                            className="ml-3"
                            type="Oval"
                            color="blue"
                            height={25}
                            width={25}
                        />
                    </div>
                    <hr className="mt-2" />
                    <Form.Group>
                        <h6>Cedula *</h6>
                        <Form.Control
                            required
                            value={this.state.ci}
                            name="ci"
                            onChange={this.handleInputChange}
                            size="sm"
                            placeholder="Ingrese la cedula" />
                    </Form.Group>
                    <Form.Row>
                        <Form.Group as={Col} >
                            <h6>Nombre *</h6>
                            <Form.Control
                                name="name"
                                value={this.state.name}
                                onChange={this.handleInputChange}
                                required
                                size="sm"
                                placeholder="Ingrese el nombre" />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Apellido *</h6>
                            <Form.Control
                                name="lastName"
                                value={this.state.lastName}
                                onChange={this.handleInputChange}
                                required
                                size="sm"
                                placeholder="Ingrese el apellido" />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <h6>Email *</h6>
                            <Form.Control
                                name="email"
                                onChange={this.handleInputChange}
                                required
                                size="sm"
                                value={this.state.email}
                                placeholder="Ingrese una dirección" />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Telefono *</h6>
                            <Form.Control
                                name="tel"
                                onChange={this.handleInputChange}
                                required
                                value={this.state.tel}
                                size="sm"
                                placeholder="Ingrese el telefono" />
                        </Form.Group>
                    </Form.Row>


                    <Form.Group>
                        <h6>Contraseña</h6>
                        <Form.Control
                            value={this.state.password}
                            name="password"
                            onChange={this.handleInputChange}
                            size="sm"
                            placeholder="Ingrese la nueva contraseña si desea cambiarla" />
                    </Form.Group>

                    {!this.state.isGeneral ?
                        <div>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <h6>Limite mensual *</h6>
                                    <Form.Control
                                        name="limMonth"
                                        onChange={this.handleInputChange}
                                        required
                                        type="number"
                                        value={this.state.limMonth}
                                        size="sm"
                                        placeholder="Ingrese el limite mensual" />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <h6>Limite por viaje * </h6>
                                    <Form.Control
                                        name="code"
                                        onChange={this.handleInputChange}
                                        required
                                        type="number"
                                        value={this.state.limTravel}
                                        size="sm"
                                        placeholder="Ingrese el limite por viaje" />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <h6>Empresa *</h6>
                                    <Form.Control name="enterprise" onChange={this.handleInputChange} value={this.state.enterprise} size="sm" as="select">
                                        <option key={0} value="">Seleccione una empresa</option>
                                        {
                                            this.state.enterprises.map(item => (
                                                <option key={item.id} value={item.numero}>{item.nombre}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <h6>Departamento *</h6>
                                    <Form.Control name="department" onChange={this.handleInputChange} value={this.state.department} size="sm" as="select">
                                        <option key={0} value="">Seleccione un departamento</option>
                                        {
                                            this.state.departments.map(item => (
                                                <option key={item.id} value={item.id}>{item.descripcion}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>
                        </div>
                        : ""}
                    <Form.Group>
                        <Form.Check onChange={this.handleCheckBoxEnable} name="isEnable" checked={this.state.isEnable} type="checkbox" label="Habilitado" />
                    </Form.Group>

                    <Form.Group>
                        <Form.Check onChange={this.handleCheckBox} name="isGeneral" checked={this.state.isGeneral} type="checkbox" label="Administrador general" />
                    </Form.Group>

                    <Button className="mt-3 mr-3" size="sm" variant="primary" type="submit">
                        Actualizar
                    </Button>

                    <Button className="mt-3" size="sm" variant="secondary" onClick={() => this.props.history.push("/admins")}>
                        Cancelar
                    </Button>
                </Form>
            </div>
        );
    }
}

export default EditarAdministrador;