import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import InputGroup from "react-bootstrap/InputGroup";
import Col from "react-bootstrap/Col";
import { FaSearch } from "react-icons/fa";
import { getSolicitudWithUUID, getObtenerSolicitud } from "../../../utils/petitions";
import PagarSolicitudForm from "./PagarSolicitudForm";
import PagarSolicitudInfo from "./PagarSolicitudInfo";
import Loader from 'react-loader-spinner';
import Alert from 'react-bootstrap/Alert';

const PagarSolicitud = (props) => {

    const [uuid, setUUID] = useState("");

    const [showPagado, setShowPagado] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [showError, setShowError] = useState(false);
    const [defineWS, setDefineWS] = useState(null);
    const [direccion, setDireccion] = useState("");
    const [id, setId] = useState("");
    const [puerta, setPuerta] = useState("");
    const [refOrig, setRefOrig] = useState("");
    const [estado, setEstado] = useState("");
    const [refDest, setRefDest] = useState("");
    const [fechaAlta, setFechaAlta] = useState("");
    const [fechaInicio, setFechaInicio] = useState("");
    const [fechaFin, setFechaFin] = useState("");
    const [tiempoTax, setTiempoTax] = useState("");
    const [idTaxi, setIdTaxi] = useState("");
    const [telPasajero, setTelPasajero] = useState("");

    const clearFlags = () => {
        setLoading(false);
        setShowForm(false);
        setShowPagado(false);
        setShowError(false);
    }

    const getTheSolicitud = async () => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getSolicitudWithUUID(header, uuid);
        if (response.status === 200) {
            if (response.data.solicitude === null) {
                return true;
            } else {
                if (response.data.solicitude.fechafin !== null) {
                    setShowPagado(true);
                    return false;
                } else {
                    const { telefonopasajero, taxi, id, direccion, numeropuerta, referenciaorigen, estado, referenciadestino, fechaalta, fechainicio, fechafin, tiempoestimadollegadataxi } = response.data.solicitude;
                    setDireccion(direccion);
                    setPuerta(numeropuerta);
                    setRefOrig(referenciaorigen);
                    setEstado(estado);
                    setRefDest(referenciadestino);
                    setFechaAlta(fechaalta);
                    setFechaInicio(fechainicio);
                    setFechaFin(fechafin);
                    setTiempoTax(tiempoestimadollegadataxi);
                    setId(id);
                    setIdTaxi(taxi);
                    setTelPasajero(telefonopasajero);

                    setDefineWS("VIAJE_HORA");
                    setShowForm(true);
                    setLoading(false);
                    return false;
                }
            }
        } else if (response.status === 401) {
            localStorage.clear();
            props.history.push("/")
            return false;
        }
    }

    const handleSubmit = async () => {
        if (uuid !== "") {
            clearFlags();

            setLoading(true);
            const response = await getTheSolicitud();
            if (response) {
                const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
                const responseServer = await getObtenerSolicitud(header, uuid);
                if (responseServer.status === 200) {
                    if (responseServer.data !== null) {
                        if (responseServer.data.idSolicitud !== null) {
                            const { telefonoPasajero, idTaxiStr, identificadorSolicitud, direccion, puerta, referenciaOrigen, estadoSolicitud, referenciaDestino, fechaAlta, fechaInicio, fechaFin, tiempoEstimadoLlegadaTaxi } = responseServer.data;
                            setDireccion(direccion);
                            setPuerta(puerta);
                            setRefOrig(referenciaOrigen);
                            setEstado(estadoSolicitud);
                            setRefDest(referenciaDestino);
                            setFechaAlta(fechaAlta);
                            setFechaInicio(fechaInicio);
                            setFechaFin(fechaFin);
                            setTiempoTax(tiempoEstimadoLlegadaTaxi);
                            setId(identificadorSolicitud);
                            setIdTaxi(idTaxiStr);
                            setTelPasajero(telefonoPasajero);

                            setDefineWS("VIAJE_NORMAL");
                            setShowForm(true)
                        } else {
                            setShowError(true);
                        }
                    }
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        }
    }

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <div className="container">
            <Form.Row>
                <Form.Group as={Col}>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text onClick={handleSubmit}>
                                <FaSearch />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            value={uuid}
                            onChange={(e) => setUUID(e.target.value)}
                            placeholder="Ingrese el UUID de la solicitud"
                            onKeyPress={handleKeypress}
                        />
                    </InputGroup>
                </Form.Group>
            </Form.Row>
            <div>
                {showPagado &&
                    <div className="d-flex justify-content-center mt-2">
                        <Alert variant="danger" className="mt-5 w-50 text-center">
                            Este viaje ya fue pagado
                        </Alert>
                    </div>
                }

                {showError &&
                    <div className="d-flex justify-content-center mt-2">
                        <Alert variant="danger" className="mt-5 w-50 text-center">
                            No se encontro la solicitud
                        </Alert>
                    </div>
                }

                {!loading && showForm ?
                    <React.Fragment>
                        <PagarSolicitudInfo
                            direccion={direccion}
                            puerta={puerta}
                            estado={estado}
                            refOrig={refOrig}
                            refDest={refDest}
                            tiempoTax={tiempoTax}
                            fechaAlta={fechaAlta}
                            uuid={uuid}
                            fechaInicio={fechaInicio}
                            fechaFin={fechaFin}
                            id={id}
                            telefonoPasajero={telPasajero}
                        />
                        <div className="justify-content-center d-flex">
                            <PagarSolicitudForm
                                defineForm={defineWS}
                                uuid={uuid}
                                fechaInicio={fechaInicio}
                                fechaFin={fechaFin}
                                id={id}
                                idTaxi={idTaxi}
                                history={props.history}
                            />
                        </div>
                    </React.Fragment>
                    :
                    <Loader
                        className="centered"
                        type="Circles"
                        color="#000"
                        visible={loading}
                        height={50}
                        width={50}
                    />
                }
            </div>
        </div>
    )
}

export default PagarSolicitud;