import React, { Component } from 'react';
import { getSolicitudes } from "../../../utils/petitions"
import Loader from 'react-loader-spinner';
import ReactDatePicker from 'react-datepicker';
import Button from 'react-bootstrap/Button';
import moment from "moment";
import { MdPictureAsPdf, MdFileDownload, MdDateRange } from "react-icons/md";
import xlsx from 'node-xlsx';
import download from 'downloadjs';
//import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import HiddenAlert from "../../components/HiddenAlert";

var utf8 = require('utf8');

class ReporteMes extends Component {

    state = {
        solicitudes: [],
        filters: {
            fechainicio: {
                filterVal: {
                    date: "2021-11-01"
                }
            },
            fechafin: {
                filterVal: {
                    date: "2021-11-17"
                }
            }
        },
        loading: true,
        loadingInicio: true,
        totalSize: "",
        startDate: "",
        error: false,
        errorText: "",
    }

    componentDidMount() {
        //this.getSolicitudesPerPageAndFilter(1,9999, this.state.filters);
        this.setState({ startDate: new Date() });
        this.onChangeDate(new Date());
    }

    setDefaultItems = () => {
        this.setState({
            error: false,
            errorText: "",
        })
    }

    onChangeDate = (date) => {
        let newDate = new Date(date)
        let dateF = newDate;
        let lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        date.setDate(1);
        dateF.setDate(lastDayOfMonth.getDate())
        let json = {
            fechainicio: {
                filterVal: {
                    date: moment(date).format('YYYY-MM-DD')
                }
            },
            fechafin: {
                filterVal: {
                    date: moment(dateF).format('YYYY-MM-DD')
                }
            }
        }
        this.setState({ startDate: newDate });
        this.setState({ filters: json });
    }

    generarPDF = async () => {
        this.setDefaultItems();
        try {
            await this.getSolicitudesPerPageAndFilter(1, 9999, this.state.filters);
            if (this.state.solicitudes) {
                let dia = this.state.startDate.getMonth() + 1;
                dia += "-" + this.state.startDate.getFullYear();
                this.setState({ loading: false });
                /*const input = document.getElementById('divToPrint');
                html2canvas(input)
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    const pdf = new jsPDF();
                    pdf.addImage(imgData, 'JPEG', 0, 0);
                    // pdf.output('dataurlnewwindow');
                    pdf.save('Reporte'+dia+'.pdf');
                });
                horizontalPageBreak: true, horizontalPageBreakRepeat: 0
                */
                const pdf = new jsPDF();
                autoTable(pdf, { html: '#my-table' })
                pdf.save('Reporte' + dia + '.pdf');
                this.setState({ loading: true });
            } else {
                this.setState({
                    error: true,
                    errorText: "No se pudo generar el reporte, revise las fechas"
                })
            }
        } catch (error) {
            throw Error(error);
        }

    }

    obtenerDatosImportarXLSX = async () => {
        let valores = [];
        let headers = ["Cédula", "Dirección", "NroTaxi", "Duración", "Monto", "Fecha Inicio", "Fecha Fin", "Transacción", "Departamento", "Pasajero", "Supervisor"];
        valores.push(headers);
        this.state.solicitudes.forEach(element => {
            let rows = [element.usuario.ci, element.direccion, element.numerotaxi, element.duracion, element.monto, element.fechainicio, element.fechafin, element.transaccion, utf8.decode(element.usuariodepartamento), element.usuariopasajero, element.usuariotelefono];
            valores.push(rows);
        });
        return valores;
    }

    generarExcel = async () => {
        this.setDefaultItems();
        try {
            await this.getSolicitudesPerPageAndFilter(1, 9999, this.state.filters);
            if (this.state.solicitudes) {
                const qwerty = await this.obtenerDatosImportarXLSX();
                let dia = this.state.startDate.getMonth() + 1;
                dia += "-" + this.state.startDate.getFullYear();
                let buffer = xlsx.build([{ name: "Reporte", data: qwerty }]).toString('base64');
                download(atob(buffer), 'Reporte' + dia + '.xlsx', { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            } else {
                this.setState({
                    error: true,
                    errorText: "No se pudo generar el reporte, revise las fechas"
                })
            }
        } catch (error) {
            throw Error(error);
        }
    }

    getSolicitudesPerPageAndFilter = async (page, pageSize, filter) => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getSolicitudes(header, page, pageSize, filter);
        if (response.status === 200) {
            if (response.data.solicitudes.length !== 0) {
                this.setState({
                    totalSize: response.data.meta.itemsCount,
                    solicitudes: response.data.solicitudes,
                })
            } else {
                this.setState({
                    totalSize: 0,
                    solicitudes: response.data.solicitudes,
                })
            }
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        } else {
            console.log(response)
        }
    }

    render() {

        const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
            <Button onClick={onClick} size="sm" variant="secondary" ref={ref} >
                <MdDateRange className="mr-1 mb-1" />
                {value}
            </Button>
        ));

        return (
            <div className="container-fluid">
                <HiddenAlert visible={this.state.error} text={this.state.errorText} variant="danger" />
                {!this.state.loadingInicio ?
                    <div>
                        <Loader
                            className="centered"
                            type="Circles"
                            color="#000"
                            visible={this.state.loading}
                            height={50}
                            width={50}
                        />
                        <h6 className="centeredTwo">Cargando...</h6>
                    </div>
                    :
                    <div>
                        <div className="d-flex">
                            <h4 className="mr-1">Solicitudes por mes</h4>
                        </div>
                        <hr className="mt-2" />
                        <div class="row justify-content-center align-items-center mt-5">
                            <h6 className="mr-1">Selecciona el mes y el año:</h6>
                        </div>
                        <div class="row justify-content-center align-items-center mt-3">
                            <div class="col-1 d-flex justify-content-center">
                                <ReactDatePicker
                                    selected={this.state.startDate}
                                    onChange={(date) => this.onChangeDate(date)}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    showFullMonthYearPicker
                                    showTwoColumnMonthYearPicker
                                    locale="es"
                                    customInput={<ExampleCustomInput />}
                                />
                            </div>
                        </div>
                        <div class="row justify-content-center align-items-center mt-3">
                            <h6 className="mr-1">Descarga el reporte</h6>
                        </div>
                        <div class="row justify-content-center align-items-center mt-3">
                            <div class="col-3 d-flex justify-content-center">
                                <Button onClick={this.generarPDF} size="sm" className="ml-3" variant="danger" >
                                    <MdPictureAsPdf className="mr-1 mb-1" />
                                    Descargar PDF
                                </Button>
                                <Button onClick={this.generarExcel} size="sm" className="ml-3" variant="success" >
                                    <MdFileDownload className="mr-1 mb-1" />
                                    Descargar Excel
                                </Button>
                            </div>
                        </div>
                        {!this.state.loading ?
                            <div id="divToPrint" className="mt4 table-responsive" style={{
                                backgroundColor: '#f5f5f5',
                                width: '210mm',
                                minHeight: '297mm',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}>
                                <table class="table" id="my-table">
                                    <thead>
                                        <tr>
                                            {/*<th scope="col">#</th>*/}
                                            <th scope="col">Cédula</th>
                                            <th scope="col">Dirección</th>
                                            <th scope="col">NroTaxi</th>
                                            <th scope="col">Duración</th>
                                            <th scope="col">Monto</th>
                                            <th scope="col">Fecha Inicio</th>
                                            <th scope="col">Fecha Fin</th>
                                            <th scope="col">Transacción</th>
                                            <th scope="col">Departamento</th>
                                            <th scope="col">Supervisor</th>
                                            {/*<th scope="col">Pasajero</th>*/}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.solicitudes.map((element, index) => (
                                            <tr key={index}>
                                                {/*<td>{index}</td>*/}
                                                <td>{element.usuario.ci}</td>
                                                <td>{element.direccion}</td>
                                                <td>{element.numerotaxi}</td>
                                                <td>{element.duracion}</td>
                                                <td>{element.monto}</td>
                                                <td>{element.fechainicio}</td>
                                                <td>{element.fechafin}</td>
                                                <td>{element.transaccion}</td>
                                                <td>{utf8.decode(element.usuariodepartamento)}</td>
                                                {/*<td>{element.usuariopasajero}</td>*/}
                                                <td>{element.usuariotelefono}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            : null
                        }
                    </div>
                }
            </div>
        );
    }
}

export default ReporteMes;