import moment from "moment";

export function solicitudeBuilder(page, pageSize, filters) {
    let params = new URLSearchParams();
    if (filters !== undefined && filters !== {}) {
        let keys = Object.keys(filters);
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] === "direccion") {
                params.append("direccionFull", filters[keys[i]].filterVal);
            }
            if (keys[i] === "fechaalta" && filters[keys[i]].filterVal.date) {
                params.append("fechaalta2", moment(filters[keys[i]].filterVal.date).zone(0).format('YYYY-MM-DD'));
            }
            if (keys[i] === "fechaenvioataxi" && filters[keys[i]].filterVal.date) {
                params.append("fechaenvioataxi2", moment(filters[keys[i]].filterVal.date).zone(0).format('YYYY-MM-DD'));
            }
            if (keys[i] === "estado") {
                params.append("estado.descripcion", filters[keys[i]].filterVal);
            }
            if (keys[i] === "numerotaxi") {
                params.append("numerotaxi", filters[keys[i]].filterVal);
            }
            if (keys[i] === "monto") {
                params.append("monto", filters[keys[i]].filterVal);
            }
            if (keys[i] === "tiempoestimadollegadataxi") {
                params.append("tiempoestimadollegadataxi", filters[keys[i]].filterVal);
            }
            if (keys[i] === "usuariopasajero") {
                params.append("usuariopasajero", filters[keys[i]].filterVal);
            }
            if (keys[i] === "transaccion") {
                params.append("transaccion", filters[keys[i]].filterVal);
            }
            if (keys[i] === "telefonopasajero") {
                params.append("telefonopasajero", filters[keys[i]].filterVal);
            }
            if (keys[i] === "id") {
                params.append("id", filters[keys[i]].filterVal);
            }
            if (keys[i] === "fechainicio" && filters[keys[i]].filterVal.date) {
                params.append("desde", moment(filters[keys[i]].filterVal.date).zone(0).format('YYYY-MM-DD'));
            }
            if (keys[i] === "fechafin" && filters[keys[i]].filterVal.date) {
                params.append("hasta", moment(filters[keys[i]].filterVal.date).zone(0).format('YYYY-MM-DD'));
            }
            if (keys[i] === "cedula") {
                params.append("cedula2", filters[keys[i]].filterVal);
            }
            if (keys[i] === "usuariodepartamento") {
                params.append("usuariodepartamento2", filters[keys[i]].filterVal);
            }
            if (keys[i] === "usuario.ci") {
                params.append("cedula2", filters[keys[i]].filterVal);
            }
            if (keys[i] === "duracion") {
                params.append("duracion2", filters[keys[i]].filterVal);
            }
        }
    }
    if (page !== undefined && pageSize !== undefined) {
        params.append("page", page);
        params.append("pageSize", pageSize);
    }
    return params;
}

export function usuariosBuilder(page, pageSize, filters) {
    let params = new URLSearchParams();
    if (filters !== undefined && filters !== {}) {
        let keys = Object.keys(filters);
        
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] === "nombre") {
                params.append("nombre", filters[keys[i]].filterVal);
            }
            if (keys[i] === "apellido") {
                params.append("apellido", filters[keys[i]].filterVal);
            }
            if (keys[i] === "ci") {
                params.append("ci", filters[keys[i]].filterVal);
            }
            if (keys[i] === "telefono") {
                params.append("telefono", filters[keys[i]].filterVal);
            }
            if (keys[i] === "email") {
                params.append("email", filters[keys[i]].filterVal);
            }
            if (keys[i] === "departamento_id.descripcion") {
                params.append("epartamento_id.descripcion", filters[keys[i]].filterVal);
            }
            if (keys[i] === "limitemensual") {
                params.append("limitemensual", filters[keys[i]].filterVal);
            }
            if (keys[i] === "limite_viaje") {
                params.append("limite_viaje", filters[keys[i]].filterVal);
            }
            if (keys[i] === "email_voyentaxi") {
                params.append("email_voyentaxi", filters[keys[i]].filterVal);
            }
            if (keys[i] === "departamento") {
                params.append("departamento", filters[keys[i]].filterVal);
            }
            if (keys[i] === "empresa") {
                params.append("empresa", filters[keys[i]].filterVal);
            }
        }
    }

    if (page !== undefined && pageSize !== undefined) {
        params.append("page", page);
        params.append("pageSize", pageSize);
    }

    return params;
}

export function crearParamsTop(fecha) {
    let params = new URLSearchParams();
    params.append("fecha", moment(fecha).zone(0).format('YYYY-MM-DD'));
    return params;
}

export function crearParamsDeptos(fecD, fecH) {
    let params = new URLSearchParams();
    params.append("fechadesde", moment(fecD).zone(0).format('YYYY-MM-DD'));
    params.append("fechahasta", moment(fecH).zone(0).format('YYYY-MM-DD'));
    return params;
}