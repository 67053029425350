import React, { Component } from 'react';
import { getDashboard } from '../../utils/petitions';
import Loader from 'react-loader-spinner';
import { MdSearch, MdDateRange, MdAttachMoney, MdAccountBalance, MdPeople, MdLocalTaxi, MdTimeline } from "react-icons/md";
import DashboardItem from "../components/DashboardItem";

class Dashboard extends Component {

    state = {
        dashboards: [],
        loading: true,
    }

    componentDidMount() {
        this.getData();
    }

    getData = async () => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getDashboard(header);
        if (response.status === 200) {
            console.log(response)
            this.setState({
                dashboards: response.data.dashboards[0],
                loading: false,
            })

        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        } else {
        }
    }

    render() {
        return (

            <div className="container-fluid">
                {(this.state.loading) ?
                    <div>
                        <Loader
                            className="centered"
                            type="Circles"
                            color="#000"
                            visible={this.state.loading}
                            height={50}
                            width={50}
                        />
                        <h6 className="centeredTwo">Cargando...</h6>
                    </div>
                    :
                    <div style={{ alignItems: "center" }}>
                        <div className="d-flex justify-content-center flex-wrap ">
                            <DashboardItem
                                icon={<MdSearch className="dashboard-icon" size={75} color="#FFD800" style={{  strokeWidth: "0.3", backgroundColor: "#FFFCD8", padding: 10, borderRadius: 100}} />}
                                title={"Cant Solicitudes Día"}
                                data={`${this.state.dashboards.cantSolicitudesD}  solicitudes`}
                            />
                            <DashboardItem
                                icon={<MdDateRange className="dashboard-icon" size={75} color="#FFD800" style={{  strokeWidth: "0.3", backgroundColor: "#FFFCD8", padding: 10, borderRadius: 100}} />}
                                title={"Cant Solicitudes Mes"}
                                data={`${this.state.dashboards.cantSolicitudesM}  solicitudes`}
                            />
                            <DashboardItem
                                icon={<MdAttachMoney size={75} color="#FFD800 "style={{  strokeWidth: "0.3", backgroundColor: "#FFFCD8", padding: 10, borderRadius: 100}} />}
                                title={"Monto Total Día"}
                                data={`${this.state.dashboards.montoD} $`}
                            />
                            <DashboardItem
                                icon={<MdAccountBalance size={75} color="#FFD800 " style={{  strokeWidth: "0.3", backgroundColor: "#FFFCD8", padding: 10, borderRadius: 100}} />}
                                title={"Monto Total Mes"}
                                data={`${this.state.dashboards.montoM} $`}
                            />
                            <DashboardItem
                                icon={<MdPeople size={75} color="#FFD800 " style={{  strokeWidth: "0.3", backgroundColor: "#FFFCD8", padding: 10, borderRadius: 100}} />}
                                title={"Cant Pasajeros registrados"}
                                data={`${this.state.dashboards.cantPasajeros} pasajeros`}
                            />
                            <DashboardItem
                                icon={<MdLocalTaxi size={75} color="#FFD800 " style={{  strokeWidth: "0.3", backgroundColor: "#FFFCD8", padding: 10, borderRadius: 100}} />}
                                title={"Cant Pasajeros Viajes/Mes"}
                                data={`${this.state.dashboards.cantPasajerosConViajes} pasajeros`}
                            />
                            <DashboardItem
                                icon={<MdTimeline size={75} color="#FFD800 " style={{  strokeWidth: "0.3", backgroundColor: "#FFFCD8", padding: 10, borderRadius: 100}} />}
                                title={"Cant Pasajeros Viajes/Usuarios"}
                                data={`${this.state.dashboards.promedioViajesPorSolicitudes}`}
                            />
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default Dashboard;