import axios from 'axios'
import { solicitudeBuilder, usuariosBuilder, crearParamsTop, crearParamsDeptos } from './paramsBuilder';

const path = "https://empresas.voyentaxi.uy/api";

/*
axios.interceptors.request.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
     console.log(response.params.toString())
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log(error)
    return Promise.reject(error);
});
*/

export const login = async (user) => {
    try {
        const respuesta = await axios.post(path + "/usuarios/sign_in", user);
        return respuesta;
    } catch (e) {
        console.log(e);
        return e.response;
    }
}

export const getSolicitudes = async (token, page, pageSize, filters) => {
    try {
        let params = solicitudeBuilder(page, pageSize, filters);
        const respuesta = await axios.get(path + "/solicitudes", { headers: { "Authorization": `Token ${token}` }, params });
        return respuesta;
    } catch (e) {
        console.log(e);
        return e.response;
    }
}

export const getSolicitudWithUUID = async (token, uuid) => {
    try {
        const respuesta = await axios.get(path + "/solicitudes/uid/" + uuid, { headers: { "Authorization": `Token ${token}` } });
        return respuesta;
    } catch (e) {
        console.log(e);
        return e.response;
    }
}

export const getObtenerSolicitud = async (token, uuid) => {
    try {
        const respuesta = await axios.get(path + "/solicitudes/obtenerSolicitud?uuid=" + uuid, { headers: { "Authorization": `Token ${token}` } });
        return respuesta;
    } catch (e) {
        console.log(e);
        return e.response;
    }
}

export const getSolicitud = async (token, id) => {
    try {
        const respuesta = await axios.get(path + "/solicitudes/" + id, { headers: { "Authorization": `Token ${token}` } });
        console.log(respuesta)
        return respuesta;
    } catch (e) {
        console.log(e);
        return e.response;
    }
}

/*
export const getSolicitudServer = async (uuid) => {
    try {
        const respuesta = await axios.get(servidor + "obtenerSolicitud" + "?uuid=" + uuid, { headers: { "Authorization": "bzhuZmE2NHJicDF3emk2a2tyc3I6a1ZEZmhkaEVvMnRMeTFJWXJ4RkE=" } });
        return respuesta;
    } catch (e) {
        console.log(e);
        return e.response;
    }
}*/

export const getUsuarios = async (token, page, pageSize, filters) => {
    try {
        let params = usuariosBuilder(page, pageSize, filters);

        const respuesta = await axios.get(path + "/usuarios", { headers: { "Authorization": `Token ${token}` }, params });
        return respuesta;
    } catch (e) {
        console.log(e);
        return e.response;
    }
}

export const getUsuario = async (token, id) => {
    try {
        const respuesta = await axios.get(path + "/usuarios/" + id, { headers: { "Authorization": `Token ${token}` } });
        return respuesta;
    } catch (e) {
        console.log(e);
        return e.response;
    }
}

export const putUsuario = async (token, id, put) => {
    try {
        const respuesta = await axios.put(path + "/usuarios/" + id, put, { headers: { "Authorization": `Token ${token}` } })
        return respuesta;
    } catch (e) {
        console.log(e);
        return e.response;
    }
}

export const postUsuario = async (token, post) => {
    try {
        const respuesta = await axios.post(path + "/usuarios", post, { headers: { "Authorization": `Token ${token}` } });
        return respuesta;
    } catch (e) {
        return e.response;
    }
}

export const getTaxis = async (token) => {
    try {
        const respuesta = await axios.get(path + "/taxis", { headers: { "Authorization": `Token ${token}` } });
        return respuesta;
    } catch (e) {
        console.log(e);
        return e.response;
    }
}

export const getTaxi = async (token, id) => {
    try {
        const respuesta = await axios.get(path + "/taxis/" + id, { headers: { "Authorization": `Token ${token}` } });
        return respuesta;
    } catch (e) {
        console.log(e);
        return e.response;
    }
}

export const getEmpresas = async (token) => {
    try {
        const respuesta = await axios.get(path + "/empresas", { headers: { "Authorization": `Token ${token}` } });
        return respuesta;
    } catch (e) {
        console.log(e);
        return e.response;
    }
}

export const getEmpresa = async (token, numero) => {
    try {
        const respuesta = await axios.get(path + "/empresas/" + numero, { headers: { "Authorization": `Token ${token}` } });
        return respuesta;
    } catch (e) {
        console.log(e);
        return e.response;
    }
}

export const postEmpresa = async (post, token) => {
    try {
        const respuesta = await axios.post(path + "/empresas", post, { headers: { "Authorization": `Token ${token}` } });
        return respuesta;
    } catch (e) {
        console.log(e)
        return e.response;
    }
}

export const putEmpresa = async (put, token, id) => {
    try {
        const respuesta = await axios.put(path + "/empresas/" + id, put, { headers: { "Authorization": `Token ${token}` } });
        return respuesta;
    } catch (e) {
        console.log(e)
        return e.response;
    }
}

export const getDepartamentos = async (token) => {
    try {
        const respuesta = await axios.get(path + "/departamentos", { headers: { "Authorization": `Token ${token}` } });
        return respuesta;
    } catch (e) {
        console.log(e);
        return e.response;
    }
}

export const getDepartamento = async (token, id) => {
    try {
        const respuesta = await axios.get(path + "/departamentos/" + id, { headers: { "Authorization": `Token ${token}` } });
        return respuesta;
    } catch (e) {
        console.log(e);
        return e.response;
    }
}

export const postDepartamento = async (post, token) => {
    try {
        const respuesta = await axios.post(path + "/departamentos", post, { headers: { "Authorization": `Token ${token}` } });
        return respuesta;
    } catch (e) {
        return e.response;
    }
}

export const putDepartamento = async (put, token, id) => {
    try {
        const respuesta = await axios.put(path + "/departamentos/" + id, put, { headers: { "Authorization": `Token ${token}` } });
        return respuesta;
    } catch (e) {
        console.log(e)
        return e.response;
    }
}

export const deleteDepartamento = async (token, id) => {
    try {
        const respuesta = await axios.delete(path + "/departamentos/" + id, { headers: { "Authorization": `Token ${token}` } });
        return respuesta;
    } catch (e) {
        console.log(e)
        return e.response;
    }
}

export const getPerfil = async (token, id) => {
    try {
        const respuesta = await axios.get(path + "/usuarios/perfil/" + id, { headers: { "Authorization": `Token ${token}` } });
        return respuesta;
    } catch (e) {
        console.log(e)
        return e.response;
    }
}

export const putPerfil = async (token, id, put) => {
    try {
        const respuesta = await axios.put(path + "/usuarios/perfil/" + id, put, { headers: { "Authorization": `Token ${token}` } });
        return respuesta;
    } catch (e) {
        console.log(e)
        return e.response;
    }
}

export const getAdmins = async (token) => {
    try {
        const respuesta = await axios.get(path + "/admins", { headers: { "Authorization": `Token ${token}` } })
        return respuesta;
    } catch (e) {
        console.log(e)
        return e.response;
    }
}

export const getAdmin = async (token, id) => {
    try {
        const respuesta = await axios.get(path + "/admins/" + id, { headers: { "Authorization": `Token ${token}` } })
        return respuesta;
    } catch (e) {
        console.log(e)
        return e.response;
    }
}

export const putAdmin = async (token, id, put) => {
    console.log(put)
    try {
        const respuesta = await axios.put(path + "/admins/" + id, put, { headers: { "Authorization": `Token ${token}` } })
        return respuesta;
    } catch (e) {
        console.log(e)
        return e.response;
    }
}

export const postAdmin = async (token, post) => {
    try {
        console.log(post)
        const respuesta = await axios.post(path + "/admins/new", post, { headers: { "Authorization": `Token ${token}` } })
        return respuesta;
    } catch (e) {
        console.log(e)
        return e.response;
    }
}

export const corporativePaymentWeb = async (token, cedula, pin, uuid, idTaxi, monto) => {
    try {
        const body = {
            cedula, pin, uuid, idTaxi, monto
        }
        const respuesta = await axios.post(path + "/solicitudes/pagar", body, { headers: { "Authorization": `Token ${token}` } });
        return respuesta;
    } catch (e) {
        console.log(e);
        return e.response;
    }
}

export const calcularViajePorHora = async (token, cedula, pin, uuid, tiempo) => {
    try {
        const data = {
            cedula,
            pin,
            uuid,
            tiempo
        }
        const respuesta = await axios.post(path + "/solicitudes/calcularViajePorHora", data, { headers: { "Authorization": `Token ${token}` } });
        return respuesta;
    } catch (e) {
        console.log(e);
        return e.response;
    }
}

export const getUsuariosLimiteMensual = async (token) => {
    try {
        const respuesta = await axios.get(path + "/analisis/usuariosporlimitemensual", { headers: { "Authorization": `Token ${token}` } });
        return respuesta;
    } catch (e) {
        console.log(e)
        return e.response;
    }
}

export const getTopUsuarios = async (token, fecha) => {
    try {
        let params = crearParamsTop(fecha);
        const respuesta = await axios.get(path + "/analisis/pasajerosMasHanUsado", { headers: { "Authorization": `Token ${token}` }, params });
        return respuesta;
    } catch (e) {
        console.log(e)
        return e.response;
    }
}

export const getDptosMensual = async (token, fecD, fecH) => {
    try {
        let params = crearParamsDeptos(fecD, fecH);
        const respuesta = await axios.get(path + "/analisis/deptosmensual", { headers: { "Authorization": `Token ${token}` }, params });
        return respuesta;
    } catch (e) {
        console.log(e)
        return e.response;
    }
}

export const getDashboard = async (token) => {
    try {
        const respuesta = await axios.get(path + "/dashboards/1", { headers: { "Authorization": `Token ${token}` } });
        return respuesta;
    } catch (e) {
        console.log(e)
        return e.response;
    }
}

