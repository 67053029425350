import React, { Component } from 'react';
import { getSolicitudes } from "../../../utils/petitions"
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, dateFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import MyExportCSV from "../../components/CsvButton";
import CustomToggleList from "../../components/CustomToggleList";
import { BiShowAlt } from "react-icons/bi";
import { FaUserAlt, FaTaxi } from "react-icons/fa";
import { Link } from "react-router-dom";
import Loader from 'react-loader-spinner';
import { estadosSolicitud } from "../../../utils/others";
import moment from "moment";
import Button from 'react-bootstrap/Button';
import { MdPayment } from "react-icons/md";
import { FaDownload } from "react-icons/fa"
import xlsx from 'node-xlsx';
import download from 'downloadjs';
import utf8 from "utf8";
//var utf8 = require('utf8');


const textFilterStyle = {
    height: 30,
    marginTop: 10
}

const dateFilterStyle = {
    backgroundColor: "white",
    height: 30,
    width: "90%",
    marginTop: 10
}

const fromIdtoState = (row) => {
    const estado = estadosSolicitud.filter(item => item.id === row.estado);
    return estado[0].nombre
}

const columns = [
    {
        dataField: 'usuario.ci',
        text: 'Cédula',
        csvText: 'Cédula',
        filter: textFilter({
            placeholder: ' ',
            style: textFilterStyle,
        }),
        headerStyle: {
            fontSize: 13,
        },
    },
    {
        dataField: 'usuario.nombre',
        text: 'Nombre',
        filter: textFilter({
            placeholder: ' ',
            style: textFilterStyle,
        }),
        headerStyle: {
            fontSize: 13,
        },
    },
    {
        dataField: 'usuario.pellido',
        text: 'Apellido',
        filter: textFilter({
            placeholder: ' ',
            style: textFilterStyle,
        }),
        headerStyle: {
            fontSize: 13,
        },
    },
    {
        dataField: 'fechaalta',
        text: 'Fecha alta',
        headerStyle: {
            fontSize: 13,
        },
        hidden: true,
        /*filter: textFilter({
            placeholder: ' ',
            style: textFilterStyle,
        }),*/
        filter: dateFilter({
            withoutEmptyComparatorOption: true,
            comparatorStyle: { display: 'none' }, // custom the style on comparator select
            dateStyle: dateFilterStyle,  // custom the style on date input
        }),
        formatter: (cell, row, rowIndex, extraData) => (
            <div>
                {
                row.fechaalta ?
                moment(row.fechaalta).zone(+3).format('DD-MM-YYYY HH:mm:ss')
                : null
                }
            </div>
        ),
    }, {
        dataField: 'fechaenvioataxi',
        text: 'Fecha envio de taxi',
        hidden: true,
        /*filter: textFilter({
            placeholder: ' ',
            style: textFilterStyle,
        }),*/
        filter: dateFilter({
            withoutEmptyComparatorOption: true,
            comparatorStyle: { display: 'none' }, // custom the style on comparator select
            dateStyle: dateFilterStyle,  // custom the style on date input
        }),
        formatter: (cell, row, rowIndex, extraData) => (
            <div>
                {
                row.fechaenvioataxi ?
                moment(row.fechaenvioataxi).zone(+3).format('DD-MM-YYYY HH:mm:ss')
                : null
                }
            </div>
        ),
        headerStyle: {
            fontSize: 13,
        },
        csvExport: false,
    }, {
        dataField: 'direccion',
        text: 'Dirección',
        csvText: 'Dirección',
        filter: textFilter({
            placeholder: ' ',
            style: textFilterStyle
        }),
        formatter: (value, row) => {
            return value + " " + row.numeropuerta
        },
        headerStyle: {
            fontSize: 13,
        },
    }, {
        dataField: 'estado',
        text: 'Estado solicitud',
        filter: textFilter({
            placeholder: ' ',
            style: textFilterStyle
        }),
        headerStyle: {
            fontSize: 13,
        },
        hidden: true,
        formatter: (cell, row, rowIndex, extraData) => (
            <div>
                {fromIdtoState(row)}
            </div>
        ),
        csvExport: false,
        formatExtraData: 10
    },
    {
        dataField: 'numerotaxi',
        text: 'NroTaxi',
        filter: textFilter({
            placeholder: ' ',
            style: textFilterStyle
        }),
        headerStyle: {
            fontSize: 13,
        },
    },
    {
        dataField: 'duracion',
        text: 'Duración',
        csvText: 'Duración',
        filter: textFilter({
            placeholder: ' ',
            style: textFilterStyle,
        }),
        headerStyle: {
            fontSize: 13,
        },
    },
    {
        dataField: 'monto',
        text: 'Monto',
        filter: textFilter({
            placeholder: ' ',
            style: textFilterStyle,
        }),
        headerStyle: {
            fontSize: 13,
        },
    },
    {
        dataField: 'fechainicio',
        text: 'Fecha Inicio',
        /*filter: textFilter({
            placeholder: ' ',
            style: textFilterStyle,
        }),*/
        filter: dateFilter({
            withoutEmptyComparatorOption: true,
            comparatorStyle: { display: 'none' }, // custom the style on comparator select
            dateStyle: dateFilterStyle,  // custom the style on date input
        }),
        formatter: (cell, row, rowIndex, extraData) => (
            <div>
                {
                row.fechainicio ?
                moment(row.fechainicio).zone(+3).format('DD-MM-YYYY HH:mm:ss')
                : null
                }
            </div>
        ),
        headerStyle: {
            fontSize: 13,
        },
    },
    {
        dataField: 'fechafin',
        text: 'Fecha Fin',
        /*filter: textFilter({
            placeholder: ' ',
            style: textFilterStyle,
        }),*/
        filter: dateFilter({
            withoutEmptyComparatorOption: true,
            comparatorStyle: { display: 'none' }, // custom the style on comparator select
            dateStyle: dateFilterStyle,  // custom the style on date input
        }),
        formatter: (cell, row, rowIndex, extraData) => (
            <div>
                {
                row.fechafin ?
                moment(row.fechafin).zone(+3).format('DD-MM-YYYY HH:mm:ss')
                : null
                }
            </div>
        ),
        headerStyle: {
            fontSize: 13,
        },
    },
    {
        dataField: 'fechapago',
        text: 'Fecha Pago',
        /*filter: textFilter({
            placeholder: ' ',
            style: textFilterStyle,
        }),*/
        filter: dateFilter({
            withoutEmptyComparatorOption: true,
            comparatorStyle: { display: 'none' }, // custom the style on comparator select
            dateStyle: dateFilterStyle,  // custom the style on date input
        }),
        formatter: (cell, row, rowIndex, extraData) => (
            <div>
                {
                row.fechapago ?
                moment(row.fechapago).zone(+3).format('DD-MM-YYYY HH:mm:ss')
                : null
                }
            </div>
        ),
        headerStyle: {
            fontSize: 13,
        },
    },
    {
        dataField: 'transaccion',
        text: 'Transacción',
        csvText: 'Transacción',
        filter: textFilter({
            placeholder: ' ',
            style: textFilterStyle,
        }),
        headerStyle: {
            fontSize: 13,
        },
    },
    {
        dataField: 'usuariodepartamento',
        text: 'Departamento',
        filter: textFilter({
            placeholder: ' ',
            style: textFilterStyle,
        }),
        formatter: (value, row) => {
            if (value) {
                return utf8.decode(value)  
            }else{
                return value;
            }
        },
        headerStyle: {
            fontSize: 13,
        },
    },
    {
        dataField: 'tiempoestimadollegadataxi',
        text: 'Tiempo llegada taxi',
        filter: textFilter({
            placeholder: ' ',
            style: textFilterStyle,
        }),
        hidden: true,
        headerStyle: {
            fontSize: 13,
        },
        csvExport: false,
    },
    {
        dataField: 'usuariopasajero',
        text: 'Pasajero',
        filter: textFilter({
            placeholder: ' ',
            style: textFilterStyle,
        }),
        hidden: true,
        headerStyle: {
            fontSize: 13,
        },
    },
    {
        dataField: 'telefonopasajero',
        text: 'Telefono pasajero',
        filter: textFilter({
            placeholder: ' ',
            style: textFilterStyle,
        }),
        hidden: true,
        headerStyle: {
            fontSize: 13,
        },
        csvExport: false,
    },
    {
        dataField: 'id',
        text: 'ID',
        hidden: true,
        filter: textFilter({
            placeholder: ' ',
            style: textFilterStyle,
        }),
        headerStyle: {
            fontSize: 13,
        },
        csvExport: false,
    },
    {
        dataField: 'ver',
        text: 'Ver',
        headerStyle: {
            fontSize: 13,
        },
        formatter: (cell, row, rowIndex, extraData) => (
            <div>
                <Link to={`/solicitudes/${row.id}`} className="btn btn-primary btn-sm"><i aria-hidden="true"><BiShowAlt /></i></Link>
            </div>
        ),
        formatExtraData: 10,
        csvExport: false,
    },
    {
        dataField: 'ver_pasajero',
        text: 'Ver Pasajero',
        hidden: true,
        headerStyle: {
            fontSize: 13,
        },
        formatter: (cell, row, rowIndex, extraData) => (
            <div style={{ justifyContent: "center", display: "flex" }}>
                <Link to={`/usuarios/usuario/${row.pasajero}`} className="btn btn-primary btn-sm">
                    <i aria-hidden="true">
                        <FaUserAlt style={{ fontSize: 12 }} />
                    </i>
                </Link>
            </div>
        ),
        csvExport: false,
    },
    {
        dataField: 'ver_taxi',
        text: 'Ver Taxi',
        hidden: true,
        headerStyle: {
            fontSize: 13,
        },
        formatter: (cell, row, rowIndex, extraData) => (
            <div style={{ justifyContent: "center", display: "flex" }}>
                <Link to={`/taxis/${row.taxi}`} className="btn btn-primary btn-sm">
                    <i aria-hidden="true">
                        <FaTaxi style={{ fontSize: 12 }} />
                    </i>
                </Link>
            </div>
        ),
        csvExport: false,
    },
];


class Solicitudes extends Component {

    state = {
        solicitudes: [],
        filters: {},
        isModal: false,
        loading: true,
        loaderPagination: false,
        totalSize: "",
        actualPage: 1,
        columns: columns,
    }

    componentDidMount() {
        this.getSolicitudes();
    }

    handlePage = (page) => {
        this.setState({
            actualPage: page
        })
    }

    changeColumnState = (columnName) => {
        let columns = this.state.columns;
        columns.forEach(column => {
            if (column.dataField === columnName) {
                column.hidden = !column.hidden
            }
        })
        this.setState({ columns: columns })
    }

    getSolicitudes = async () => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getSolicitudes(header);
        if (response.status === 200) {
            this.setState({
                solicitudes: response.data.solicitudes,
                totalSize: response.data.meta.itemsCount,
                loading: false,
            })
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    getSolicitudesPerPageAndFilter = async (page, pageSize, filter) => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getSolicitudes(header, page, pageSize, filter);
        if (response.status === 200) {
            if (response.data.solicitudes.length !== 0) {
                this.setState({
                    totalSize: response.data.meta.itemsCount,
                    solicitudes: response.data.solicitudes,
                    loaderPagination: false
                })
            } else {
                this.setState({
                    totalSize: 0,
                    solicitudes: response.data.solicitudes,
                    loaderPagination: false
                })
            }
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        } else {
            console.log(response)
        }
    }

    onTableChange = async (type, newState) => {
        await this.getSolicitudesPerPageAndFilter(newState.page, newState.sizePerPage, newState.filters);
    }

    setSolicitudesForEnteprise = (solicitudes) => {
        return solicitudes.filter(item => item.empresa === localStorage.getItem("empresa"));
    }

    generarExcel = async () => {
    
        try {
    
            const qwerty = await this.obtenerDatosImportarXLSX();
    
            let buffer = xlsx.build([{ name: "Solicitudes", data: qwerty }]).toString('base64');
            download(atob(buffer), 'Solicitudes.xlsx', { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    
        } catch (error) {
            throw Error(error);
        }
    
    }

    obtenerDatosImportarXLSX = async () => {
        let valores = [];
        let headers = ["Cédula", "Nombre", "Apellido", "Fecha Alta", "Dirección", "NroTaxi", "Duración", "Monto", "Fecha Inicio", "Fecha Fin", "Fecha Pago", "Transacción", "Departamento", "Pasajero"];
        valores.push(headers);
        this.state.solicitudes.forEach(element => {
            let rows = [element.usuario.ci, element.usuario.nombre, element.usuario.pellido, element.fechaalta, element.direccion, element.numerotaxi, element.duracion, element.monto, element.fechainicio, element.fechafin, element.fechapago, element.transaccion, utf8.decode(element.usuariodepartamento), element.usuariopasajero];
            valores.push(rows);
        });
        return valores;
    }

    render() {

        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                &nbsp;&nbsp;Mostrando filas del { from } al { to } de { size } resultados.
            </span>
          );

        return (
            <div className="container-fluid">
                {this.state.loading ?
                    <div>
                        <Loader
                            className="centered"
                            type="Circles"
                            color="#000"
                            visible={this.state.loading}
                            height={50}
                            width={50}
                        />
                        <h6 className="centeredTwo">Cargando...</h6>
                    </div>
                    :
                    <div>
                        <div className="d-flex">
                            <h4 className="mr-1">Solicitudes</h4>
                            {
                                localStorage.getItem("isAdmin") ?
                                    <Button onClick={() => this.props.history.push(`pagar`)} size="sm" className="ml-3" variant="primary" >
                                        <MdPayment className="mr-1 mb-1" />
                                        Pagar viaje corporativo
                                    </Button>
                                    : <div></div>
                            }
                            <Loader
                                className="ml-3 mt-1"
                                type="ThreeDots"
                                color="black"
                                height={25}
                                width={30}
                                visible={this.state.loaderPagination}
                            />
                        </div>
                        <hr className="mt-2" />
                        <ToolkitProvider
                            keyField="id"
                            data={this.state.solicitudes}
                            columns={columns}
                            exportCSV={{
                                fileName: 'Solicitudes.csv',
                            }}
                            columnToggle
                            wrapperClasses="table-responsive " // This is the style provided by bootstrap 4, this will set the parent div with that class
                        >
                            {
                                props => (
                                    <div>
                                        <CustomToggleList
                                            changeColumnState={this.changeColumnState}
                                            {...props.columnToggleProps}
                                        />
                                        <BootstrapTable {...props.baseProps} filter={filterFactory()}
                                            striped
                                            remote
                                            onTableChange={this.onTableChange}
                                            hover
                                            condensed
                                            loading={this.state.loaderPagination}
                                            pagination={paginationFactory({
                                                sizePerPageList:[ {
                                                    text: '10', value: 10
                                                  }, {
                                                    text: '25', value: 25
                                                  }, {
                                                    text: '50', value: 50
                                                  }, {
                                                    text: '100', value: 100
                                                  }, {
                                                    text: '1000', value: 1000
                                                  }, {
                                                    text: 'Todos', value: this.state.totalSize
                                                  } ],
                                                showTotal: true,
                                                totalSize: this.state.totalSize,
                                                onPageChange: (page, sizePerPage) => {
                                                    this.setState({
                                                        loaderPagination: true,
                                                        actualPage: page
                                                    })
                                                },
                                                paginationTotalRenderer: customTotal,
                                            })}
                                            rowStyle={{ fontSize: 12 }}
                                        />
                                        <div className="row ml-1">
                                            <MyExportCSV {...props.csvProps} data={this.state.solicitudes} />
                                            <Button size="sm" className="btn btn-success ml-2"  onClick={() => this.generarExcel()}><FaDownload /> Descargar XLSX</Button>
                                        </div>
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    </div>
                }
            </div>
        );
    }
}

export default Solicitudes;