import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from "react-bootstrap/Col";
import { postEmpresa } from "../../../utils/petitions";
import Loader from 'react-loader-spinner';

class CrearEmpresa extends Component {

    state = {
        showLoader: false,
        name: "",
        address: "",
        contact: "",
        email: "",
        country: "",
        city: "",
        bussinesName: "",
        limMonth: "",
        code: "",
        priceHour: "",
    }

    handleInputChange = ({ target: { name, value } }) => {
        this.setState({ [name]: value })
    }

    handleClickButton = async (e) => {
        this.setState({ showLoader: true })
        e.preventDefault();
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const post = {
            empresa: {
                nombre: this.state.name,
                direccion: this.state.address,
                contacto: this.state.contact,
                email: this.state.email,
                pais: this.state.country,
                ciudad: this.state.city,
                razonsocial: this.state.bussinesName,
                limite_mensual: this.state.limMonth,
                codigo: this.state.code,
                precio_hora: this.state.priceHour
            }
        }
        const response = await postEmpresa(post, header);
        if (response.status === 200) {
            this.props.history.push("/empresas")
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    render() {
        return (
            <div className="container">
                <Form onSubmit={this.handleClickButton}>
                    <div className="d-flex">
                        <h4>Registro de nueva Empresa</h4>
                        <Loader
                            visible={this.state.showLoader}
                            className="ml-3"
                            type="Oval"
                            color="blue"
                            height={25}
                            width={25}
                        />
                    </div>
                    <hr className="mt-2" />
                    <Form.Row>
                        <Form.Group as={Col} >
                            <h6>Nombre *</h6>
                            <Form.Control name="name" onChange={this.handleInputChange} required size="sm" placeholder="Ingrese el nombre" />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Email *</h6>
                            <Form.Control name="email" onChange={this.handleInputChange} required size="sm" placeholder="Ingrese el email de la empresa" />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <h6>Dirección *</h6>
                            <Form.Control name="address" onChange={this.handleInputChange} required size="sm" placeholder="Ingrese una dirección" />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Contacto *</h6>
                            <Form.Control name="contact" onChange={this.handleInputChange} required size="sm" placeholder="Ingrese un número de contacto" />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <h6>Pais *</h6>
                            <Form.Control name="country" onChange={this.handleInputChange} required size="sm" placeholder="Ingrese un pais" />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Ciudad *</h6>
                            <Form.Control name="city" onChange={this.handleInputChange} required size="sm" placeholder="Ingrese una ciudad" />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <h6>Razón social *</h6>
                            <Form.Control name="bussinesName" onChange={this.handleInputChange} required size="sm" placeholder="Ingrese el RUC de la empresa" />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Precio viajes por hora *</h6>
                            <Form.Control type="number" step="any" name="priceHour" onChange={this.handleInputChange} required size="sm" placeholder="Ingrese el precio por hora" />
                        </Form.Group>
                    </Form.Row>

                    <Form.Group >
                        <h6>Limite mensual *</h6>
                        <Form.Control name="limMonth" onChange={this.handleInputChange} required type="number" size="sm" placeholder="Ingrese el limite mensual de la empresa" />
                    </Form.Group>

                    <Form.Group >
                        <h6>Codigo * </h6>
                        <Form.Control name="code" onChange={this.handleInputChange} required type="number" size="sm" placeholder="Ingrese el codigo de la empresa" />
                    </Form.Group>
                    <Button className="mt-3 mr-2" size="sm" variant="primary" type="submit">
                        Crear empresa
                    </Button>

                    <Button className="mt-3 mr-3" size="sm" variant="secondary" onClick={() => this.props.history.push("/empresas")}>
                        Cancelar
                    </Button>
                </Form>
            </div>
        );
    }
}

export default CrearEmpresa;