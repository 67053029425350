import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { getUsuario, putUsuario, getEmpresa, getDepartamento } from "../../../utils/petitions";
import { BiShowAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import Loader from 'react-loader-spinner';
import { FiCircle, FiDisc } from "react-icons/fi";

const PassengerInfo = (props) => {
    return (
        <Table size="sm" striped bordered hover className="mr-5">
            <tbody>
                <tr>
                    <td className="font-weight-bold" colSpan="3" style={{ backgroundColor: "white", borderColor: "#CDCDCD" }}> Detalles del pasajero</td>
                </tr>
                <tr>
                    <td className="font-weight-bold" colSpan="2">Teléfono:</td>
                    <td >{props.telefono}</td>
                </tr>
                <tr>
                    <td className="font-weight-bold" colSpan="2">Límite mensual:</td>
                    <td colSpan="2">{props.limiteMensual}</td>
                </tr>
                <tr>
                    <td className="font-weight-bold" colSpan="2">Límite por viaje:</td>
                    <td colSpan="2">{props.limiteViaje}</td>
                </tr>
            </tbody>
        </Table>
    )
}

const UserInfo = (props) => {
    return (
        <Table size="sm" striped bordered hover className="mr-5">
            <tbody>
                <tr>
                    <td className="font-weight-bold" colSpan="3" style={{ backgroundColor: "white", borderColor: "#CDCDCD" }}> Detalles del usuario</td>
                </tr>
                <tr>
                    <td className="font-weight-bold" colSpan="2">Nombre:</td>
                    <td >{props.nombre}</td>
                </tr>
                <tr>
                    <td className="font-weight-bold" colSpan="2">Apellido:</td>
                    <td colSpan="2">{props.apellido}</td>
                </tr>
                <tr>
                    <td className="font-weight-bold" colSpan="2">Empresa:</td>
                    <td colSpan="2">{props.empresa}</td>
                </tr>
                <tr>
                    <td className="font-weight-bold" colSpan="2">Departamento:</td>
                    <td colSpan="2">{props.departamento}</td>
                </tr>
                <tr>
                    <td className="font-weight-bold" colSpan="2">Email encargado:</td>
                    <td colSpan="2">{props.emailEncargado}</td>
                </tr>
                <tr>
                    <td className="font-weight-bold" colSpan="2">Email vet:</td>
                    <td colSpan="2">{props.email_voyentaxi}</td>
                </tr>
                <tr>
                    <td className="font-weight-bold" colSpan="2">Email:</td>
                    <td colSpan="2">{props.email}</td>
                </tr>
            </tbody>
        </Table>
    )
}

const SystemInformation = (props) => {
    return (
        <Table size="sm" striped bordered hover>
            <tbody>
                <tr>
                    <td className="font-weight-bold" colSpan="3" style={{ backgroundColor: "white", borderColor: "#CDCDCD" }}>Datos del sistema</td>
                </tr>
                <tr>
                    <td className="font-weight-bold" colSpan="2">Usuario:</td>
                    <td></td>
                </tr>
                <tr>
                    <td className="font-weight-bold" colSpan="2">Fecha de alta:</td>
                    <td colSpan="2">{props.fechaCreado}</td>
                </tr>
                <tr>
                    <td className="font-weight-bold" colSpan="2">Últ modificacion:</td>
                    <td colSpan="2">{props.fechaModificado}</td>
                </tr>
                <tr>
                    <td className="font-weight-bold" colSpan="2">Teléfono verificado:</td>
                    <td colSpan="2"></td>
                </tr>
            </tbody>
        </Table>
    )
}

const SolicitudesInfo = (props) => {
    return (
        <Table size="sm" striped hover>
            <thead>
                <th>Dirección</th>
                <th>Fecha solicitud</th>
                <th>Ver</th>
            </thead>
            <tbody>
                {
                    props.data !== undefined ?
                        props.data.map(item => (
                            <tr key={item.code}>
                                <td>{item.direccion}</td>
                                <td>{item.fechaenvioataxi}</td>
                                <td>
                                    <Link to={`/solicitudes/${item.id}`} className="btn btn-primary btn-sm">
                                        <i aria-hidden="true">
                                            <BiShowAlt />
                                        </i>
                                    </Link>
                                </td>
                            </tr>
                        ))
                        : null
                }
            </tbody>
        </Table>
    )
}

function Usuario(props) {
    const [dataUsuario, setDataUsuario] = useState([]);
    const [dataSolicitud, setDataSolicitud] = useState([]);
    const [loading, setLoading] = useState(true);
    const [empresa, setEmpresa] = useState("");
    const [departamento, setDepartamento] = useState("");

    useEffect(() => {
        const getTheUsuario = async () => {
            const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
            const response = await getUsuario(header, props.match.params.id)
            if (response.status === 200) {
                setDataUsuario(response.data.usuario)
                getTheEmpresa(response.data.usuario.empresa_id);
                getTheDepartamento(response.data.usuario.departamento_id);
                setDataSolicitud(response.data.solicitudes)
            } else if (response.status === 401) {
                localStorage.clear();
                props.history.push("/")
            }
        }
        const getTheEmpresa = async (number) => {
            const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
            const response = await getEmpresa(header, number);
            if (response.status === 200) {
                setEmpresa(response.data.empresa.nombre)
            } else if (response.status === 401) {
                localStorage.clear();
                props.history.push("/")
            }
        }

        const getTheDepartamento = async (number) => {
            const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
            const response = await getDepartamento(header, number);
            if (response.status === 200) {
                setDepartamento(response.data.departamento.descripcion)
            } else if (response.status === 401) {
                localStorage.clear();
                props.history.push("/")
            }
            setLoading(false);
        }

        getTheUsuario();

    }, [props.match.params.id, props.history]);

    const toggleStatus = async (value) => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const put = {
            usuario: {
                ...dataUsuario,
                habilitado: value
            }
        }
        const response = await putUsuario(header, props.match.params.id, put);

        if (response.status === 200) {
            setDataUsuario(response.data.usuario)
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    return (
        <div className="container ">
            {
                loading ?
                    <div>
                        <Loader
                            className="centered"
                            type="Circles"
                            color="#000"
                            visible={loading}
                            height={50}
                            width={50}
                        />
                        <h6 className="centeredTwo">Cargando...</h6>
                    </div>
                    :
                    <div>
                        <div className="d-flex">
                            <h4>{dataUsuario.nombre} {dataUsuario.apellido}</h4>
                            {
                                dataUsuario.habilitado === 1 ?
                                    <Button onClick={() => toggleStatus("0")} className="btn btn-success  ml-4 font-weight-bold" size="sm">
                                        <FiDisc className="mb-1" /> Habilitado
                                    </Button>
                                    :
                                    <Button onClick={() => toggleStatus("1")} className="btn btn-secondary ml-4 font-weight-bold" size="sm">
                                        <FiCircle className="mb-1" /> Inhabilitado
                                    </Button>
                            }

                        </div>
                        <hr className="mt-1" />
                        <Tabs defaultActiveKey="informacion" >
                            <Tab eventKey="informacion" title="Información">
                                <Col>
                                    <UserInfo
                                        nombre={dataUsuario.nombre}
                                        apellido={dataUsuario.apellido}
                                        empresa={empresa}
                                        departamento={departamento}
                                        emailEncargado={dataUsuario.telefono}
                                        email_voyentaxi={dataUsuario.email_voyentaxi}
                                        email={dataUsuario.email}

                                    />
                                    <PassengerInfo
                                        telefono={dataUsuario.telefono_original}
                                        limiteMensual={dataUsuario.limitemensual}
                                        limiteViaje={dataUsuario.limite_viaje}
                                    />
                                    <SystemInformation
                                        fechaCreado={dataUsuario.fecha_creado}
                                        fechaModificado={dataUsuario.fecha_modificado} />
                                </Col>
                            </Tab>
                            <Tab eventKey="solicitudes" title="Solicitudes">
                                <div className="mt-2">
                                    <SolicitudesInfo
                                        data={dataSolicitud}
                                    />
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
            }
        </div>
    )
}

export default Usuario;