import React, { Component } from 'react';
import { getAdmins } from "../../../utils/petitions";
import Loader from 'react-loader-spinner';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import MyExportCSV from "../../components/CsvButton";
import CustomToggleList from "../../components/CustomToggleList";
import { Link } from "react-router-dom";
import { BiShowAlt } from "react-icons/bi";
import { HiUserAdd } from "react-icons/hi";
import { Button } from "react-bootstrap";

const textFilterStyle = {
    height: 30,
    marginTop: 10
}

const columns = [{
    dataField: 'id',
    text: 'ID',
    hidden: true,
    headerStyle: {
        fontSize: 13,
    },
    filter: textFilter({
        placeholder: ' ',
        style: textFilterStyle
    })
},
{
    dataField: 'ci',
    text: 'Cedula',
    headerStyle: {
        fontSize: 13,
    },
    filter: textFilter({
        placeholder: ' ',
        style: textFilterStyle
    })
},
{
    dataField: 'nombre',
    text: 'Nombre',
    headerStyle: {
        fontSize: 13,
    },
    filter: textFilter({
        placeholder: ' ',
        style: textFilterStyle
    })
}, {
    dataField: 'apellido',
    text: 'Apellido',
    headerStyle: {
        fontSize: 13,
    },
    filter: textFilter({
        placeholder: ' ',
        style: textFilterStyle
    })
},
{
    dataField: 'email',
    text: 'Email',
    headerStyle: {
        fontSize: 13,
    },
    filter: textFilter({
        placeholder: ' ',
        style: textFilterStyle
    })
},
{
    dataField: 'limitemensual',
    text: 'Limite mensual',
    headerStyle: {
        fontSize: 13,
    },
    filter: textFilter({
        placeholder: ' ',
        style: textFilterStyle
    })
},
{
    dataField: 'lim_viaje',
    text: 'Limite Viaje',
    headerStyle: {
        fontSize: 13,
    },
    filter: textFilter({
        placeholder: ' ',
        style: textFilterStyle
    })
},
{
    dataField: 'telefono',
    text: 'Telefono',
    headerStyle: {
        fontSize: 13,
    },
    filter: textFilter({
        placeholder: ' ',
        style: textFilterStyle
    })
},
{
    dataField: 'habilitado',
    text: 'Estado',
    headerStyle: {
        fontSize: 13,
    },
    filter: textFilter({
        placeholder: ' ',
        style: textFilterStyle
    }),
    formatter: (cell, row, rowIndex, extraData) => (
        row.habilitado === "1" ?
            <div>Habilitado</div>
            :
            <div>Inhabilitado</div>
    ),
},
{
    dataField: 'tipo',
    text: 'Tipo',
    headerStyle: {
        fontSize: 13,
    },
    filter: textFilter({
        placeholder: ' ',
        style: textFilterStyle
    })
},
{
    dataField: 'editar',
    text: 'Editar',
    headerStyle: {
        fontSize: 13,
    },
    filter: textFilter({
        placeholder: ' ',
        style: textFilterStyle
    }),
    formatter: (cell, row, rowIndex, extraData) => (
        <div style={{ justifyContent: "center", display: "flex" }}>
            <Link to={`/admins/editar/${row.id}`} className="btn btn-primary btn-sm "><i aria-hidden="true"><BiShowAlt /></i></Link>
        </div>
    ),
    formatExtraData: 10
}

]

class Administradores extends Component {
    state = {
        admins: [],
        loading: true,
    }

    componentDidMount() {
        this.getTheAdmins();
    }

    getTheAdmins = async () => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getAdmins(header);
        console.log(response)
        if (response.status === 200) {
            this.setState({
                admins: response.data.admin,
                loading: false
            })
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    render() {
        return (
            <div className="container-fluid">
                {this.state.loading ?
                    <div>
                        <Loader
                            className="centered"
                            type="Circles"
                            color="#000"
                            visible={this.state.loading}
                            height={50}
                            width={50}
                        />
                        <h6 className="centeredTwo">Cargando...</h6>
                    </div>
                    :
                    <div>
                        <div className="d-flex">
                            <h4>Administradores</h4>
                            <Link style={{ color: "#fff" }} to={`/admins/crear`}>
                                <Button size="sm" className="ml-3" >
                                    Nuevo
                                <HiUserAdd />
                                </Button>
                            </Link>
                        </div>
                        <hr className="mt-2" />
                        <ToolkitProvider
                            keyField="id"
                            data={this.state.admins}
                            columns={columns}
                            exportCSV
                            columnToggle
                            wrapperClasses="table-responsive " // This is the style provided by bootstrap 4, this will set the parent div with that class
                        >
                            {
                                props => (
                                    <div>
                                        <CustomToggleList
                                            {...props.columnToggleProps}
                                        />
                                        <BootstrapTable {...props.baseProps} filter={filterFactory()}
                                            striped
                                            hover
                                            condensed
                                            pagination={paginationFactory()}
                                            rowStyle={{ fontSize: 12 }}
                                        />
                                        <MyExportCSV {...props.csvProps} />
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    </div>
                }
            </div>
        );
    }
}

export default Administradores;