import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import { getPerfil, putPerfil } from "../../../utils/petitions";
import Toast from 'react-bootstrap/Toast';
import Loader from 'react-loader-spinner';

class Perfil extends Component {

    state = {
        name: "",
        lastName: "",
        tel: "",
        email: "",
        limMonth: "",
        limTravel: "",
        totalMonth: "",
        password: "",
        showLoader: false,
        toastShow: false
    }

    componentDidMount() {
        this.getThePerfil();
    }

    handleClickButton = (e) => {
        e.preventDefault();
        this.putThePerfil();
    }

    handleInputChange = ({ target: { name, value } }) => {
        this.setState({ [name]: value })
    }

    putThePerfil = async () => {
        this.setState({
            showLoader: true
        })
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        let put = {
            usuario: {
                nombre: this.state.name,
                apellido: this.state.lastName,
                telefono: this.state.tel,
                email: this.state.email,
                password: this.state.password,
                //limite_viaje: this.state.limTravel,
                //limitemensual: this.state.limMonth
            }
        }
        const response = await putPerfil(header, this.props.match.params.id, put);
        if (response.status === 200) {
            this.setState({
                toastShow: true,
                showLoader: false
            })
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    getThePerfil = async () => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getPerfil(header, this.props.match.params.id);
        if (response.status === 200) {
            this.setState({
                name: response.data.usuario.nombre,
                lastName: response.data.usuario.apellido,
                tel: response.data.usuario.telefono,
                email: response.data.usuario.email,
                limMonth: response.data.usuario.limitemensual,
                limTravel: response.data.usuario.limite_viaje,
                totalMonth: response.data.montoactual
            })
        }
        else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    render() {
        return (
            <div className="container">
                <Toast className="toast rounded " onClose={() => this.setState({ toastShow: false })} show={this.state.toastShow} delay={3000} autohide>
                    <Toast.Body className="bg-success text-white rounded ">El perfil ha sido editado correctamente</Toast.Body>
                </Toast>
                <Form onSubmit={this.handleClickButton}>
                    <div className="d-flex">
                        <h4>Editar mi perfil</h4>
                        <Loader
                            visible={this.state.showLoader}
                            className="ml-3"
                            type="Oval"
                            color="blue"
                            height={25}
                            width={25}
                        />
                    </div>
                    <hr className="mt-2" />
                    <Form.Row>
                        <Form.Group as={Col} className="">
                            <h6>Nombre *</h6>
                            <Form.Control value={this.state.name} name="name" onChange={this.handleInputChange} required size="sm" placeholder="Ingrese el nombre" />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Apellido *</h6>
                            <Form.Control value={this.state.lastName} name="lastName" onChange={this.handleInputChange} required size="sm" placeholder="Ingrese el apellido" />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <h6>Telefono *</h6>
                            <Form.Control value={this.state.tel} name="tel" onChange={this.handleInputChange} required size="sm" placeholder="Ingrese el telefono" />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Email *</h6>
                            <Form.Control value={this.state.email} name="email" onChange={this.handleInputChange} required size="sm" placeholder="Ingrese el email" />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <h6>Nueva contraseña *</h6>
                            <Form.Control value={this.state.password} name="password" onChange={this.handleInputChange} type="password" size="sm" placeholder="Ingrese la nueva contraseña si desea cambiarla" />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Confirmar nueva contraseña *</h6>
                            <Form.Control value={this.state.confirmPassword} name="confirmPassword" onChange={this.handleInputChange} type="password" size="sm" placeholder="Ingrese la contraseña nuevamente si desea cambiarla" />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <h6>Limite mensual</h6>
                            <InputGroup size="sm">
                                <InputGroup.Prepend  >
                                    <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    defaultValue={this.state.limMonth}
                                    size="sm"
                                    type="number"
                                    disabled
                                />
                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Total mes</h6>
                            <InputGroup size="sm">
                                <InputGroup.Prepend  >
                                    <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    defaultValue={this.state.totalMonth}
                                    size="sm"
                                    type="number"
                                    disabled
                                />
                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Limite de viaje</h6>
                            <InputGroup size="sm">
                                <InputGroup.Prepend  >
                                    <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    defaultValue={this.state.limTravel}
                                    size="sm"
                                    type="number"
                                    disabled
                                />
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>

                    <Button className="mt-3" size="sm" variant="primary" type="submit">
                        Actualizar
                    </Button>
                </Form>
            </div>
        );
    }
}

export default Perfil;