import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { getDepartamento, putDepartamento } from '../../../utils/petitions';
import Loader from 'react-loader-spinner';

class EditarDepartamento extends Component {

    state = {
        showLoader: false,
        departamento: "",
        description: "",
        minDescription: "",
    }

    componentDidMount() {
        this.getTheDepartamento();
    }

    getTheDepartamento = async () => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getDepartamento(header, this.props.match.params.id);
        if (response.status === 200) {
            this.setState({
                description: response.data.departamento.descripcion,
                minDescription: response.data.departamento.descripcion_abv
            })
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    handleInputChange = ({ target: { name, value } }) => {
        this.setState({ [name]: value })
    }

    handleClickButton = async (e) => {
        e.preventDefault();
        this.setState({
            showLoader: true
        })
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const put = {
            departamento: {
                descripcion: this.state.description,
                descripcion_abv: this.state.minDescription,
                habilitado: 1//?
            }
        }
        const response = await putDepartamento(put, header, this.props.match.params.id);
        if (response.status === 200) {
            this.props.history.push("/departamentos")
        }
    }

    render() {
        return (
            <div className="container">
                <Form onSubmit={this.handleClickButton}>
                    <div className="d-flex">
                        <h4>Editar departamento</h4>
                        <Loader
                            visible={this.state.showLoader}
                            className="ml-3"
                            type="Oval"
                            color="blue"
                            height={25}
                            width={25}
                        />
                    </div>
                    <hr className="mt-2" />
                    <Form.Group className="mt-4">
                        <h6>Descripción *</h6>
                        <Form.Control
                            name="description"
                            onChange={this.handleInputChange}
                            value={this.state.description}
                            required
                            size="sm"
                            placeholder="Ingrese una descripción" />
                    </Form.Group>

                    <Form.Group>
                        <h6>Descripción abreviada</h6>
                        <Form.Control
                            name="minDescription"
                            onChange={this.handleInputChange}
                            defaultValue={this.state.minDescription}
                            size="sm"
                            placeholder="Ingrese una descripción abreviada" />
                    </Form.Group>

                    <Button className="mt-3 mr-3" size="sm" variant="primary" type="submit">
                        Actualizar
                    </Button>

                    <Button className="mt-3" size="sm" variant="secondary" onClick={() => this.props.history.push("/departamentos")}>
                        Cancelar
                    </Button>
                </Form>
            </div>
        );
    }
}

export default EditarDepartamento;