import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import { FaPencilAlt } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { HiUserAdd } from "react-icons/hi";
import { getDepartamentos, deleteDepartamento } from "../../../utils/petitions";
import Loader from 'react-loader-spinner';
import Modal from 'react-bootstrap/Modal';
import Toast from 'react-bootstrap/Toast';

class Departamentos extends Component {
    state = {
        departamentos: [],
        loading: true,
        modalShow: false,
        departamentoToRemove: "",
        toastShow: false,
    }

    componentDidMount() {
        this.getTheDepartamentos();
    }

    deleteTheDepartamento = async () => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await deleteDepartamento(header, this.state.departamentoToRemove);
        if (response.status === 200) {
            let filterByDepartment = this.state.departamentos.filter(item => item.id !== this.state.departamentoToRemove);
            this.setState({
                departamentos: filterByDepartment,
                modalShow: false,
                toastShow: true
            })
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    openModal = (id) => {
        this.setState({
            modalShow: true,
            departamentoToRemove: id
        })
    }

    getTheDepartamentos = async () => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getDepartamentos(header);
        if (response.status === 200) {
            this.setState({
                departamentos: response.data.departamentos,
                loading: false,
            })
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    render() {
        return (
            <div className="container">

                <Modal
                    show={this.state.modalShow}
                    onHide={() => this.setState({ modalShow: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            Eliminar
                </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>¿Seguro que desea eliminar este departamento?</Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.deleteTheDepartamento}>Aceptar</Button>
                        <Button className="btn btn-secondary" onClick={() => this.setState({ modalShow: false })}>Cancelar</Button>
                    </Modal.Footer>
                </Modal>

                <Toast className="toast rounded " onClose={() => this.setState({toastShow: false})} show={this.state.toastShow} delay={3000} autohide>
                    <Toast.Body className="bg-success text-white rounded ">Se elimino correctamente el departamento</Toast.Body>
                </Toast>

                {this.state.loading ?
                    <div>
                        <Loader
                            className="centered"
                            type="Circles"
                            color="#000"
                            visible={this.state.loading}
                            height={50}
                            width={50}
                        />
                        <h6 className="centeredTwo">Cargando...</h6>
                    </div>
                    :
                    <div>
                        <div className="d-flex">
                            <h4>Departamentos</h4>
                            <Link style={{ color: "#fff" }} to={`/departamentos/crear`}>
                                <Button size="sm" className="ml-3" >
                                    Nuevo
                            <HiUserAdd />
                                </Button>
                            </Link>
                        </div>

                        <Table responsive className="mt-3">
                            <thead>
                                <tr>
                                    <th>Descripción</th>
                                    <th>Descripción abreviada</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.departamentos !== undefined ?
                                    this.state.departamentos.map(elemento => (
                                        <tr key={elemento.id}>
                                            <td>{elemento.descripcion}</td>
                                            <td>{elemento.descripcion_abv}</td>
                                            <td>
                                                <Link style={{ color: "#fff" }} to={`/departamentos/editar/${elemento.id}`}>
                                                    <Button size="sm" className="mr-2 rounded-pill">
                                                        Editar
                                                <FaPencilAlt className="actions" style={{ height: 18, width: 30, marginLeft: 5 }} />
                                                    </Button>
                                                </Link>

                                                <Button size="sm" className="btn btn-danger rounded-pill" onClick={() => this.openModal(elemento.id)}>
                                                    Remover
                                                <MdDeleteForever className="actions" style={{ height: 20, width: 30 }} />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                    : ""
                                }
                            </tbody>
                        </Table>
                    </div>
                }
            </div>
        );
    }
}

export default Departamentos;