import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import MyExportCSV from "../../components/CsvButton";
import CustomToggleList from "../../components/CustomToggleList";
import { BiShowAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { FaPencilAlt } from "react-icons/fa";
import { getUsuarios, getDepartamentos, getEmpresas } from "../../../utils/petitions";
import Loader from 'react-loader-spinner';

const textFilterStyle = {
    height: 30,
    marginTop: 10
}

const columns = [{
    dataField: 'nombre',
    text: 'Nombre',
    headerStyle: {
        fontSize: 13,
    },
    filter: textFilter({
        placeholder: ' ',
        style: textFilterStyle
    })
}, {
    dataField: 'apellido',
    text: 'Apellido',
    filter: textFilter({
        placeholder: ' ',
        style: textFilterStyle
    }),
    headerStyle: {
        fontSize: 13,
    },
}, {
    dataField: 'ci',
    text: 'Nro documento',
    filter: textFilter({
        placeholder: ' ',
        style: textFilterStyle
    }),
    headerStyle: {
        fontSize: 13,
    },
}, {
    dataField: 'telefono',
    text: 'Email encargado',
    filter: textFilter({
        placeholder: ' ',
        style: textFilterStyle
    }),
    headerStyle: {
        fontSize: 13,
    },
}, {
    dataField: 'email_voyentaxi',
    text: 'Email vet',
    filter: textFilter({
        placeholder: ' ',
        style: textFilterStyle
    }),
    headerStyle: {
        fontSize: 13,
    },
},{
    dataField: 'email',
    text: 'E-mail',
    filter: textFilter({
        placeholder: ' ',
        style: textFilterStyle
    }),
    headerStyle: {
        fontSize: 13,
    },
}, {
    dataField: 'departamento',
    text: 'Departamento',
    filter: textFilter({
        placeholder: ' ',
        style: textFilterStyle
    }),
},
{
    dataField: 'empresa',
    text: 'Empresa',
    filter: textFilter({
        placeholder: ' ',
        style: textFilterStyle
    }),
}, {
    dataField: 'limitemensual',
    text: 'Limite mensual',
    filter: textFilter({
        placeholder: ' ',
        style: textFilterStyle
    }),
    headerStyle: {
        fontSize: 13,
    },
}, {
    dataField: 'limite_viaje',
    text: 'Limite viaje',
    filter: textFilter({
        placeholder: ' ',
        style: textFilterStyle
    }),
    headerStyle: {
        fontSize: 13,
    },
}, {
    dataField: 'ver',
    text: 'Ver',
    headerStyle: {
        fontSize: 13,
    },
    formatter: (cell, row, rowIndex, extraData) => (
        <div>
            <Link to={`/usuarios/usuario/${row.id}`} className="btn btn-primary btn-sm"><i aria-hidden="true"><BiShowAlt /></i></Link>
        </div>
    ),
    formatExtraData: 10
}, {
    dataField: 'editar',
    text: 'Editar',
    headerStyle: {
        fontSize: 13,
    },
    formatter: (cell, row, rowIndex, extraData) => (
        <div>
            <Link to={`/usuarios/editar/${row.id}`} className="btn btn-primary btn-sm"><i aria-hidden="true"><FaPencilAlt /></i></Link>
        </div>
    ),
}]

class Usuarios extends Component {

    constructor(props) {
        super(props);
        this.state = {
            departamentos: this.getTheDepartamentos(),
            usuarios: this.getUsuarios(),
            empresas: this.getTheEmpresas(),
            totalSize: "",
            loading: true,
            loaderPagination: false,
            columns: columns,
        };
    }

    changeColumnState = (columnName) => {
        let columns = this.state.columns;
        columns.forEach(column => {
            if (column.dataField === columnName) {
                column.hidden = !column.hidden
            }
        })
        this.setState({ columns: columns })
    }

    getTheDepartamentos = async () => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getDepartamentos(header);
        if (response.status === 200) {
            this.setState({
                departamentos: response.data.departamentos,
            })
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    getTheEmpresas = async () => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getEmpresas(header);
        if (response.status === 200) {
            this.setState({
                empresas: response.data.empresas,
            })
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    getUsuarios = async () => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getUsuarios(header);
        if (response.status === 200) {
            this.setState({
                usuarios: response.data.usuarios,
                totalSize: response.data.meta.itemsCount,
                loading: false
            })
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    getUsuariosPerPageAndFilter = async (page, pageSize, filters) => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getUsuarios(header, page, pageSize, filters);
        console.log(response)
        if (response.status === 200) {
            if (response.data.usuarios.length !== 0) {
                this.setState({
                    totalSize: response.data.meta.itemsCount,
                    usuarios: response.data.usuarios,
                    loaderPagination: false
                })
            } else {
                this.setState({
                    totalSize: 0,
                    usuarios: response.data.usuarios,
                    loaderPagination: false
                })
            }
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    onTableChange = async (type, newState) => {
        await this.getUsuariosPerPageAndFilter(newState.page, newState.sizePerPage, newState.filters);
    }

    render() {

        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                &nbsp;&nbsp;Mostrando filas del { from } al { to } de { size } resultados.
            </span>
          );

        return (
            <div className="container-fluid">
                {this.state.loading ?
                    <div>
                        <Loader
                            className="centered"
                            type="Circles"
                            color="#000"
                            visible={this.state.loading}
                            height={50}
                            width={50}
                        />
                        <h6 className="centeredTwo">Cargando...</h6>
                    </div>
                    :
                    <div>
                        <h4>Usuarios</h4>
                        <hr className="mt-2" />
                        <ToolkitProvider
                            data={this.state.usuarios}
                            keyField="id"
                            columns={columns}
                            exportCSV={{

                            }}
                            columnToggle
                            wrapperClasses="table-responsive" // This is the style provided by bootstrap 4, this will set the parent div with that class
                        >
                            {
                                props => (
                                    <div>
                                        <CustomToggleList
                                            changeColumnState={this.changeColumnState}
                                            {...props.columnToggleProps}
                                        />
                                        <BootstrapTable {...props.baseProps} filter={filterFactory()}
                                            striped
                                            hover
                                            remote
                                            condensed
                                            onTableChange={this.onTableChange}
                                            loading={this.state.loaderPagination}
                                            pagination={paginationFactory({
                                                sizePerPageList:[ {
                                                    text: '10', value: 10
                                                  }, {
                                                    text: '25', value: 25
                                                  }, {
                                                    text: '50', value: 50
                                                  }, {
                                                    text: '100', value: 100
                                                  }, {
                                                    text: '1000', value: 1000
                                                  }, {
                                                    text: 'Todos', value: this.state.totalSize
                                                  } ],
                                                showTotal: true,
                                                totalSize: this.state.totalSize,
                                                onPageChange: (page, sizePerPage) => {
                                                    this.setState({
                                                        loaderPagination: true,
                                                        actualPage: page
                                                    })
                                                },
                                                paginationTotalRenderer: customTotal,
                                            })}
                                            rowStyle={{ fontSize: 12 }}
                                        />
                                        <MyExportCSV {...props.csvProps} data={this.state.solicitudes}/>
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    </div>
                }
            </div>
        );
    }
}

export default Usuarios;