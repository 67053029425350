import React, { Component } from 'react';
import { getDptosMensual } from "../../../utils/petitions"
import Loader from 'react-loader-spinner';
import moment from "moment";
import Button from 'react-bootstrap/Button';
import ReactDatePicker from 'react-datepicker';
import { MdPictureAsPdf, MdFileDownload } from "react-icons/md";
import xlsx from 'node-xlsx';
import download from 'downloadjs';
//import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import HiddenAlert from "../../components/HiddenAlert";

var utf8 = require('utf8');

class ReporteDepartamento extends Component {

    state = {
        departamentos: [],
        loading: true,
        loadingInicio: true,
        error: false,
        errorText: "",
    }

    componentDidMount() {
        //this.getData()
        this.setState({ startDate: new Date() });
        this.setState({ toDate: new Date() });
    }

    generarPDF = async () => {
        this.setDefaultItems();
        try {
            await this.getData(this.state.startDate, this.state.toDate);
            if (this.state.departamentos) {
                this.setState({ loading: false });
                const pdf = new jsPDF();
                autoTable(pdf, { html: '#my-table' })
                pdf.save('Reporte.pdf');

                this.setState({ loading: true });
            } else {
                this.setState({
                    error: true,
                    errorText: "No se pudo generar el reporte, revise las fechas"
                })
            }
        } catch (error) {
            throw Error(error);
        }
    }

    setDefaultItems = () => {
        this.setState({
            error: false,
            errorText: "",
        })
    }

    obtenerDatosImportarXLSX = async () => {
        let valores = [];
        let headers = ["Departamento", "Monto", "Fecha desde", "Fecha hasta"];
        valores.push(headers);
        this.state.departamentos.forEach(element => {
            let rows = [utf8.decode(element.descripcion), element.monto, moment(this.state.startDate).zone(0).format('DD-MM-YYYY'), moment(this.state.toDate).zone(0).format('DD-MM-YYYY')];
            valores.push(rows);
        });
        return valores;
    }

    generarExcel = async () => {
        this.setDefaultItems();
        try {
            await this.getData(this.state.startDate, this.state.toDate);
            if (this.state.departamentos) {
                const qwerty = await this.obtenerDatosImportarXLSX();
                let buffer = xlsx.build([{ name: "Reporte", data: qwerty }]).toString('base64');
                download(atob(buffer), 'Reporte.xlsx', { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            } else {
                this.setState({
                    error: true,
                    errorText: "No se pudo generar el reporte, revise las fechas"
                })
            }
        } catch (error) {
            throw Error(error);
        }
    }

    getData = async (fecD, fecH) => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getDptosMensual(header, fecD, fecH);
        if (response.status === 200) {
            this.setState({
                departamentos: response.data.departamentos,
            })
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        } else {
        }
    }

    onChangeDateDesde = (date) => {
        let newDate = new Date(date)
        this.setState({ startDate: newDate });
    }

    onChangeDateHasta = (date) => {
        let newDateH = new Date(date)
        this.setState({ toDate: newDateH });
    }

    render() {

        const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
            <Button onClick={onClick} size="sm" className="ml-3 mr-3" variant="secondary" ref={ref} >
                {value}
            </Button>
        ));

        return (
            <div className="container-fluid">
                <HiddenAlert visible={this.state.error} text={this.state.errorText} variant="danger" />
                {!this.state.loadingInicio ?
                    <div>
                        <Loader
                            className="centered"
                            type="Circles"
                            color="#000"
                            visible={this.state.loading}
                            height={50}
                            width={50}
                        />
                        <h6 className="centeredTwo">Cargando...</h6>
                    </div>
                    :
                    <div>
                        <div className="d-flex">
                            <h4 className="mr-1">Departamentos</h4>
                        </div>
                        <hr className="mt-2" />
                        <div class="row justify-content-center align-items-center mt-5">
                            <h6 className="mr-1">Selecciona el rango de fecha:</h6>
                        </div>
                        <div class="row justify-content-center align-items-center mt-3">
                            <div class="col-2 d-flex justify-content-center">
                                <p>Desde:</p>
                                <ReactDatePicker
                                    selected={this.state.startDate}
                                    onChange={(date) => this.onChangeDateDesde(date)}
                                    dateFormat="dd/MM/yyyy"
                                    locale="es"
                                    customInput={<ExampleCustomInput />}
                                />
                                <p>Hasta:</p>
                                <ReactDatePicker
                                    selected={this.state.toDate}
                                    onChange={(date) => this.onChangeDateHasta(date)}
                                    dateFormat="dd/MM/yyyy"
                                    locale="es"
                                    customInput={<ExampleCustomInput />}
                                />
                            </div>
                        </div>
                        <div class="row justify-content-center align-items-center mt-3">
                            <h6 className="mr-1">Descarga el reporte</h6>
                        </div>
                        <div class="row justify-content-center align-items-center mt-3">
                            <div class="col-3 d-flex justify-content-center">
                                <Button onClick={this.generarPDF} size="sm" className="ml-3" variant="danger" >
                                    <MdPictureAsPdf className="mr-1 mb-1" />
                                    Descargar PDF
                                </Button>
                                <Button onClick={this.generarExcel} size="sm" className="ml-3" variant="success" >
                                    <MdFileDownload className="mr-1 mb-1" />
                                    Descargar Excel
                                </Button>
                            </div>
                        </div>
                        {!this.state.loading ?
                            <div id="divToPrint" className="mt4 table-responsive" style={{
                                backgroundColor: '#f5f5f5',
                                width: '210mm',
                                minHeight: '297mm',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}>
                                <table class="table" id="my-table">
                                    <thead>
                                        <tr>
                                            {/*<th scope="col">#</th>*/}
                                            <th scope="col">Departamento</th>
                                            <th scope="col">Monto</th>
                                            <th scope="col">Fecha desde</th>
                                            <th scope="col">Fecha hasta</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.departamentos.map((element, index) => (
                                            <tr key={index}>
                                                {/*<td>{index}</td>*/}
                                                <td>{utf8.decode(element.descripcion)}</td>
                                                <td>{element.monto}</td>
                                                <td>{moment(this.state.startDate).zone(0).format('DD-MM-YYYY')}</td>
                                                <td>{moment(this.state.toDate).zone(0).format('DD-MM-YYYY')}</td>
                                                {/*<td>{element.usuariopasajero}</td>*/}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            : null
                        }
                    </div>
                }
            </div>
        );
    }
}

export default ReporteDepartamento;