import React from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { IndexLinkContainer, LinkContainer } from "react-router-bootstrap";
import { FaUserAlt } from "react-icons/fa"
import { HiUsers } from "react-icons/hi"

export default function Header() {

    const logOut = () => {
        localStorage.clear();
    }

    const NavBarPermissions = () => {
        if (localStorage.getItem("token") !== null) {
            if (!localStorage.getItem("isAdmin")) {
                return (
                    <Navbar collapseOnSelect expand="lg" style={{ backgroundColor: "#feeb00", borderRadius: 1 }} >
                        <LinkContainer to="/solicitudes">
                            <Navbar.Brand><img src="/react/logo_141.png" alt="dashboards" style={{width: 60, heigth:60}}></img></Navbar.Brand>
                        </LinkContainer>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="mr-auto">
                                <IndexLinkContainer to="/solicitudes">
                                    <Nav.Link>Solicitudes</Nav.Link>
                                </IndexLinkContainer>
                            </Nav>
                            <Nav className="mr-auto" style={{marginLeft: -300}}>
                                <h3>{localStorage.getItem("empresaName")}</h3>
                            </Nav>
                            <Nav style={{ marginRight: 10 }}>
                                <NavDropdown title={localStorage.getItem("email")} id="collasible-nav-dropdown">
                                    <LinkContainer to={"/perfil/" + localStorage.getItem("id")}>
                                        <NavDropdown.Item> <FaUserAlt /> Ver perfil </NavDropdown.Item>
                                    </LinkContainer>
                                    <NavDropdown.Divider />
                                    <IndexLinkContainer to="/">
                                        <NavDropdown.Item onClick={logOut}>Salir</NavDropdown.Item>
                                    </IndexLinkContainer>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                )
            } else {
                return (
                    <Navbar collapseOnSelect expand="lg" style={{ backgroundColor: "#feeb00", borderRadius: 1 }} >
                        <LinkContainer to="/dashboard">
                            <Navbar.Brand><img src="/react/logo_141.png" alt="dashboards" style={{width: 60, heigth:60}}></img></Navbar.Brand>
                        </LinkContainer>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="mr-auto">
                                <IndexLinkContainer to="/solicitudes">
                                    <Nav.Link>Solicitudes</Nav.Link>
                                </IndexLinkContainer>

                                {(localStorage.getItem("isGral")) &&
                                <NavDropdown title="Empresas" id="collasible-nav-dropdown">
                                    <IndexLinkContainer to="/empresas">
                                        <NavDropdown.Item>Listar</NavDropdown.Item>
                                    </IndexLinkContainer>
                                    <IndexLinkContainer to="/empresas/crear">
                                        <NavDropdown.Item >Agregar</NavDropdown.Item>
                                    </IndexLinkContainer>
                                </NavDropdown>
                                }

                                <NavDropdown title="Departamentos" id="collasible-nav-dropdown">
                                    <IndexLinkContainer to="/departamentos">
                                        <NavDropdown.Item>Listar</NavDropdown.Item>
                                    </IndexLinkContainer>
                                    <IndexLinkContainer to="/departamentos/crear">
                                        <NavDropdown.Item>Agregar</NavDropdown.Item>
                                    </IndexLinkContainer>
                                    <IndexLinkContainer to="/departamentos/asociar">
                                        <NavDropdown.Item>Asociar</NavDropdown.Item>
                                    </IndexLinkContainer>
                                </NavDropdown>

                                <NavDropdown title="Usuarios" id="collasible-nav-dropdown">
                                    <IndexLinkContainer to="/usuarios">
                                        <NavDropdown.Item>Listar</NavDropdown.Item>
                                    </IndexLinkContainer>
                                    <IndexLinkContainer to="/usuarios/crear">
                                        <NavDropdown.Item>Agregar</NavDropdown.Item>
                                    </IndexLinkContainer>
                                </NavDropdown>

                                <NavDropdown title="Reportes" id="collasible-nav-dropdown">
                                    <IndexLinkContainer to="/reportes/departamento">
                                        <NavDropdown.Item>Departamento</NavDropdown.Item>
                                    </IndexLinkContainer>
                                    <IndexLinkContainer to="/reportes/solicitud">
                                        <NavDropdown.Item>Solicitudes por mes</NavDropdown.Item>
                                    </IndexLinkContainer>
                                    <IndexLinkContainer to="/reportes/topUsuario">
                                        <NavDropdown.Item>Top usuarios</NavDropdown.Item>
                                    </IndexLinkContainer>
                                    <IndexLinkContainer to="/reportes/usuarioLimMes">
                                        <NavDropdown.Item>Usuarios limite mensual</NavDropdown.Item>
                                    </IndexLinkContainer>
                                </NavDropdown>
                            </Nav>
                            {(!localStorage.getItem("isGral")) &&
                            <div style={{marginLeft: -300}} className="mr-auto">
                                <h3>{localStorage.getItem("empresaName")}</h3>
                            </div>
                            }
                            <Nav style={{ marginRight: 10 }}>
                                <NavDropdown title={localStorage.getItem("email")} id="collasible-nav-dropdown">
                                    <LinkContainer to={"/perfil/" + localStorage.getItem("id")}>
                                        <NavDropdown.Item> <FaUserAlt /> Ver perfil </NavDropdown.Item>
                                    </LinkContainer>
                                    <NavDropdown.Divider />
                                    <LinkContainer to="/admins">
                                        <NavDropdown.Item><HiUsers style={{ fontSize: 20 }} /> Administrar</NavDropdown.Item>
                                    </LinkContainer>
                                    <NavDropdown.Divider />
                                    <IndexLinkContainer to="/">
                                        <NavDropdown.Item onClick={logOut}>Salir</NavDropdown.Item>
                                    </IndexLinkContainer>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                )
            }
        } else {
            return (
                <Navbar collapseOnSelect expand="lg" style={{ backgroundColor: "#feeb00", borderRadius: 1 }} >
                    <Navbar.Brand><img src="/react/logo_141.png" alt="dashboards" style={{width: 60, heigth:60}}></img></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                </Navbar>
            )
        }
    }

    return (
        <div>
            <NavBarPermissions />
        </div >
    );
}
