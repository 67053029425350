import React from "react";
import Table from 'react-bootstrap/Table';
import moment from "moment";

const PagarSolicitudInfo = (props) => {
    return (
        <Table size="sm" className="mt-5" striped bordered hover>
            <tbody>
                <tr>
                    <td className="font-weight-bold" colSpan="4" style={{ backgroundColor: "white", borderColor: "#CDCDCD" }}> Datos de solicitud</td>
                </tr>
                <tr>
                    <td className="font-weight-bold" >Dirección:</td>
                    <td colSpan="3">{props.direccion} {props.puerta}
                        <br></br>

                        <p style={{ fontStyle: "italic", color: "#7A7A7A" }}>Referencia: {props.refOrig}</p>
                    </td>
                </tr>
                <tr>
                    <td className="font-weight-bold">Fecha alta:</td>
                    <td>{moment(props.fechaAlta).zone(0).format('DD-MM-YYYY HH:mm:ss')}</td>
                    <td className="font-weight-bold">Fecha de inicio:</td>
                    <td>{props.fechaInicio}</td>
                </tr>
                <tr>
                    <td className="font-weight-bold">Telefono pasajero:</td>
                    <td>{props.telefonoPasajero}</td>
                    <td className="font-weight-bold">Fecha de fin:</td>
                    <td>{(props.fechaFin)}</td>
                </tr>
                <tr>
                    <td className="font-weight-bold">Referencia destino:</td>
                    <td>{props.refDest}</td>
                    <td className="font-weight-bold">Tiempo estimado llegada:</td>
                    <td>{props.tiempoTax}</td>
                </tr>

            </tbody>
        </Table>
    )
}

export default PagarSolicitudInfo;