import React from 'react';
import { DropdownButton, ButtonGroup, Dropdown } from 'react-bootstrap';
import { BiShowAlt } from "react-icons/bi"
import { GiSightDisabled } from "react-icons/gi"

const CustomToggleList = ({
    columns,
    onColumnToggle,
    toggles,
    changeColumnState
}) => (
    <DropdownButton
        size="sm"
        title="Columnas"
        as={ButtonGroup}
        style={{ justifyContent: "end", display: "end" }}
    >
        {
            columns
                .map(column => ({
                    ...column,
                    toggle: toggles[column.dataField]
                }))
                .map(column => (
                    <Dropdown.Item style={{ height: 25, fontSize: 13 }} onClick={() => {
                        onColumnToggle(column.dataField)
                        if (changeColumnState) changeColumnState(column.dataField)
                    }
                    }
                        key={column.dataField}>
                        {column.toggle ?
                            <div >
                                <BiShowAlt style={{ marginRight: 10 }} /> {column.text}
                            </div> :
                            <div>
                                <GiSightDisabled style={{ marginRight: 10 }} /> {column.text}
                            </div>}
                    </Dropdown.Item>

                ))
        }

    </DropdownButton>

);
export default CustomToggleList;