import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';

const HiddenAlert = (props) => {

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const onShowAlert = () => {
            if (props.visible) {
                setVisible(true)
                setTimeout(() => setVisible(false), 2000)
            }
        }
        onShowAlert();
    }, [props.visible])

    return (
        <div className="d-flex justify-content-center mt-2">
            <div style={{
                position: "fixed",
                bottom: 30,
            }}>
                <Alert variant={props.variant} className=" w-70 text-center" show={visible}>
                    {props.text}
                </Alert>
            </div>
        </div>
    );
}

export default HiddenAlert;
