import React, { Component } from 'react';
import { login } from "../../utils/petitions";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Loader from 'react-loader-spinner';
import HiddenAlert from "../components/HiddenAlert";

class Login extends Component {

    state = {
        email: "",
        password: "",
        error: false,
        errorText: "",
        spinnerShow: false,
    }

    componentDidMount() {
        if (localStorage.getItem("token"))
            this.props.history.push("/dashboard")
    }

    onChangeEmail = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    onChangePassword = (e) => {
        this.setState({
            password: e.target.value
        })
    }

    toggleSpinnerShow = () => {
        this.setState({
            spinnerShow: !this.state.spinnerShow
        })
    }

    onClickButton = async (e) => {
        e.preventDefault();
        const { email, password } = this.state
        this.setDefaultItems();
        if (email !== "" && password !== "") {
            this.toggleSpinnerShow();
            const user = {
                user: {
                    email,
                    password
                }
            }
            const respuesta = await login(user);
            this.toggleSpinnerShow();

            if (respuesta.status === 401) {
                this.setState({
                    error: true,
                    errorText: "Usuario o contraseña incorrectos"
                })
            }

            if (respuesta.status === 200) {
                localStorage.setItem('email', respuesta.data.email);
                localStorage.setItem('token', respuesta.data.token);
                localStorage.setItem('id', respuesta.data.user.id);
                localStorage.setItem('empresaName', respuesta.data.empresa);
                if (respuesta.data.user.is_admin === 1) {
                    localStorage.setItem('isAdmin', respuesta.data.user.is_admin);
                }
                if (respuesta.data.user.is_gral === 1) {
                    localStorage.setItem('isGral', respuesta.data.user.is_gral);
                }
                localStorage.setItem('empresa', respuesta.data.user.empresa_id);
                this.props.history.push("/dashboard")
            }
        } else {
            this.setState({
                error: true,
                errorText: "Ingrese todos los campos"
            })
        }
    }

    setDefaultItems = () => {
        this.setState({
            spinnerShow: false,
            error: false,
            errorText: "",
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="d-flex justify-content-center">
                    <Card className="mt-5" style={{ backgroundColor: "#FFF", width: "40%" }}>
                        <Card.Body >
                            <Card.Title className="text-center" >Ingreso al sistema</Card.Title>
                            <Form onSubmit={this.onClickButton} className="mt-4">
                                <Form.Group >
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control required placeholder="Ingrese el usuario" onChange={this.onChangeEmail} />
                                </Form.Group>

                                <Form.Group >
                                    <Form.Label>Contraseña</Form.Label>
                                    <Form.Control required type="password" placeholder="Ingrese la contraseña" onChange={this.onChangePassword} />
                                </Form.Group>
                                <div className="text-center">
                                    {
                                        !this.state.spinnerShow ?
                                            <Button type="submit" className="mt-3 w-100" variant="primary">
                                                Ingresar
                                            </Button>
                                            :
                                            <Button disabled className="mt-3 w-100" variant="primary">
                                                <Loader
                                                    className="ml-3"
                                                    type="ThreeDots"
                                                    color="white"
                                                    height={25}
                                                    width={30}

                                                />
                                            </Button>
                                    }
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </div >
                <HiddenAlert text={this.state.errorText} visible={this.state.error} variant="danger" />
            </React.Fragment>
        );
    }
}

export default Login;