import React, { Component } from 'react';
import { getUsuariosLimiteMensual } from "../../../utils/petitions"
import Loader from 'react-loader-spinner';
import Button from 'react-bootstrap/Button';
import { MdPictureAsPdf, MdFileDownload } from "react-icons/md";
import xlsx from 'node-xlsx';
import download from 'downloadjs';
//import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import HiddenAlert from "../../components/HiddenAlert";

var utf8 = require('utf8');

class ReporteUsuarioLimMes extends Component {

    state = {
        usuarios: [],
        loading: true,
        loadingInicio: true,
        error: false,
        errorText: "",
    }

    componentDidMount() {
        //this.getData()
    }

    setDefaultItems = () => {
        this.setState({
            error: false,
            errorText: "",
        })
    }

    generarPDF = async () => {
        this.setDefaultItems();
        try {
            await this.getData()
            if (this.state.usuarios) {
                this.setState({ loading: false });
                const pdf = new jsPDF();
                autoTable(pdf, { html: '#my-table' })
                pdf.save('Reporte.pdf');
                this.setState({ loading: true });
            } else {
                this.setState({
                    error: true,
                    errorText: "No se pudo generar el reporte, revise las fechas"
                })
            }
        } catch (error) {
            throw Error(error);
        }

    }

    obtenerDatosImportarXLSX = async () => {
        let valores = [];
        let headers = ["Documento", "Nombre", "Monto", "Límite mensual", "Porcentaje"];
        valores.push(headers);
        this.state.usuarios.forEach(element => {
            let rows = [element.ci, utf8.decode(element.nombre), element.monto, element.limitemensual, element.porcentaje];
            valores.push(rows);
        });
        return valores;
    }

    generarExcel = async () => {
        this.setDefaultItems();
        try {
            await this.getData()
            if (this.state.usuarios) {
                const qwerty = await this.obtenerDatosImportarXLSX();
                let buffer = xlsx.build([{ name: "Reporte", data: qwerty }]).toString('base64');
                download(atob(buffer), 'Reporte.xlsx', { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            } else {
                this.setState({
                    error: true,
                    errorText: "No se pudo generar el reporte, revise las fechas"
                })
            }
        } catch (error) {
            throw Error(error);
        }
    }

    getData = async () => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getUsuariosLimiteMensual(header);
        console.log(response)
        if (response.status === 200) {

            this.setState({
                usuarios: response.data.usuarios,
            })

        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        } else {
            console.log(response)
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <HiddenAlert visible={this.state.error} text={this.state.errorText} variant="danger" />
                {!this.state.loadingInicio ?
                    <div>
                        <Loader
                            className="centered"
                            type="Circles"
                            color="#000"
                            visible={this.state.loading}
                            height={50}
                            width={50}
                        />
                        <h6 className="centeredTwo">Cargando...</h6>
                    </div>
                    :
                    <div>
                        <div className="d-flex">
                            <h4 className="mr-1">Usuarios limite mensual</h4>
                        </div>
                        <hr className="mt-2" />
                        <div class="row justify-content-center align-items-center mt-3">
                            <h6 className="mr-1">Descarga el reporte</h6>
                        </div>
                        <div class="row justify-content-center align-items-center mt-3">
                            <div class="col-3 d-flex justify-content-center">
                                <Button onClick={this.generarPDF} size="sm" className="ml-3" variant="danger" >
                                    <MdPictureAsPdf className="mr-1 mb-1" />
                                    Descargar PDF
                                </Button>
                                <Button onClick={this.generarExcel} size="sm" className="ml-3" variant="success" >
                                    <MdFileDownload className="mr-1 mb-1" />
                                    Descargar Excel
                                </Button>
                            </div>
                        </div>
                        {!this.state.loading ?
                            <div id="divToPrint" className="mt4 table-responsive" style={{
                                backgroundColor: '#f5f5f5',
                                width: '210mm',
                                minHeight: '297mm',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}>
                                <table class="table" id="my-table">
                                    <thead>
                                        <tr>
                                            {/*<th scope="col">#</th>*/}
                                            <th scope="col">Documento</th>
                                            <th scope="col">Nombre</th>
                                            <th scope="col">Monto</th>
                                            <th scope="col">Límite mensual</th>
                                            <th scope="col">Porcentaje</th>
                                            {/*<th scope="col">Pasajero</th>*/}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.usuarios.map((element, index) => (
                                            <tr key={index}>
                                                {/*<td>{index}</td>*/}
                                                <td>{element.ci}</td>
                                                <td>{utf8.decode(element.nombre)}</td>
                                                <td>{element.monto}</td>
                                                <td>{element.limitemensual}</td>
                                                <td>{element.porcentaje}</td>
                                                {/*<td>{element.usuariopasajero}</td>*/}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            : null
                        }
                    </div>
                }
            </div>
        );
    }
}

export default ReporteUsuarioLimMes;