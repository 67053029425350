import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import { getEmpresas, getDepartamentos, putEmpresa } from "../../../utils/petitions";
import Toast from 'react-bootstrap/Toast';

class AsociarDepartamento extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            enterprises: [],
            departments: [],
            showToast: false,
            enterpriseSelected: [],
            departmentsSelected: [],
        }
      }

    componentDidMount() {
        this.getTheDepartaments();
        this.getTheEmpresas();
    }

    getTheEmpresas = async () => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getEmpresas(header);
        if (response.status === 200) {
            this.setState({
                enterprises: response.data.empresas,
            })
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    getTheDepartaments = async () => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getDepartamentos(header);
        if (response.status === 200) {
            this.setState({
                departments: response.data.departamentos,
            })
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    putTheEnterprise = async () => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const put = {
            empresa: {
                departamentos: this.state.departmentsSelected.map(item => item.id),
                nombre: this.state.enterpriseSelected.nombre,
                email: this.state.enterpriseSelected.email,
                direccion: this.state.enterpriseSelected.direccion,
                contacto: this.state.enterpriseSelected.contacto,
                pais: this.state.enterpriseSelected.pais,
                ciudad: this.state.enterpriseSelected.ciudad,
                razonsocial: this.state.enterpriseSelected.razonsocial,
                limite_mensual: this.state.enterpriseSelected.limite_mensual,
                codigo: this.state.enterpriseSelected.codigo,
                //habilitado: this.state.enable
            }
        }

        const response = await putEmpresa(put, header, this.state.enterpriseSelected.numero)
        if (response.status === 200) {
            this.setState({
                showToast: true,
                enterpriseSelected: [],
                departmentsSelected: []
            })
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    handleClickButton = (e) => {
        e.preventDefault();
        this.putTheEnterprise();
        /*
        cantChoferes: null
cantPasajerosConViajes: null
cantSolicitudes: null
cantTaxis: null
ciudad: "Medellin"
codigo: 1407
contacto: "test123"
departamentos: ["7", "8", "4"]
direccion: "testeando"
email: "test@gmail.com"
estadosGrafica: null
fechamodificado: null
habilitado: true
limite_mensual: 10000
nombre: "test cpatu test"
pais: "Colombia"
promedioViajesPorSolicitudes: null
razonsocial: "testing"
urlfoto: null
valoresGrafica: null
*/
    }

    handleSelectBoxEnteprise = (e) => {
        this.setState({
            enterpriseSelected: e,
            departmentsSelected: this.getValue(this.state.departments, e !== undefined ? e.departamentos : [])
        })
    }

    handleSelectBoxDepartments = (e) => {
        this.setState({
            departmentsSelected: e
        })
    }

    getValue = (opts, val) => {
        try {
            return opts.filter(o => val.includes(parseInt(o.id)));
        } catch (e) {
        }
    };

    state = {}
    render() {
        return (
            <div className="container">
                <Toast className="toast rounded " onClose={() => this.setState({ showToast: false })} show={this.state.showToast} delay={3000} autohide>
                    <Toast.Body className="bg-success text-white rounded ">Se asociaron los departamentos correctamente</Toast.Body>
                </Toast>
                <h4>Asociar departamentos a empresa</h4>
                <hr className="mt-2" />
                <Form onSubmit={this.handleClickButton}>
                    <Row>
                        <Col>
                            <Form.Group>
                                <h6>Empresas *</h6>
                                <Select
                                    required
                                    onChange={this.handleSelectBoxEnteprise}
                                    getOptionLabel={opt => opt.nombre}
                                    getOptionValue={opt => opt.id}
                                    placeholder="Seleccione una empresa"
                                    value={this.state.enterpriseSelected}
                                    options={this.state.enterprises} />

                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <h6>Departamentos *</h6>
                                <Select
                                    onChange={this.handleSelectBoxDepartments}
                                    getOptionLabel={opt => opt.descripcion}
                                    getOptionValue={opt => opt.id}
                                    placeholder="Seleccione un departamento"
                                    isMulti
                                    value={this.state.departmentsSelected}
                                    options={this.state.departments} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Button style={{ marginTop: 25 }} size="sm" variant="primary" type="submit">
                                Guardar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

export default AsociarDepartamento;