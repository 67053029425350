import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from "react-bootstrap/Col";
import { getEmpresa, putEmpresa } from "../../../utils/petitions";
import Loader from 'react-loader-spinner';

class EditarEmpresa extends Component {

    state = {
        enable: "",
        name: "",
        address: "",
        contact: "",
        email: "",
        country: "",
        city: "",
        bussinesName: "",
        limMonth: "",
        code: "",
        priceHour: "",
        showLoader: false,
    }

    componentDidMount() {
        this.getTheEmpresa();
    }

    handleInputChange = ({ target: { name, value } }) => {
        this.setState({ [name]: value })
    }

    getTheEmpresa = async () => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getEmpresa(header, this.props.match.params.id);
        if (response.status === 200) {
            this.setState({
                name: response.data.empresa.nombre,
                address: response.data.empresa.direccion,
                contact: response.data.empresa.contacto,
                email: response.data.empresa.email,
                country: response.data.empresa.pais,
                city: response.data.empresa.ciudad,
                bussinesName: response.data.empresa.razonsocial,
                limMonth: response.data.empresa.limite_mensual,
                code: response.data.empresa.codigo,
                enable: response.data.empresa.habilitado,
                priceHour: response.data.empresa.precio_hora
            })
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    handleClickButton = async (e) => {
        e.preventDefault();
        this.setState({
            showLoader: true
        })
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const put = {
            empresa: {
                nombre: this.state.name,
                email: this.state.email,
                direccion: this.state.address,
                contacto: this.state.contact,
                pais: this.state.country,
                ciudad: this.state.city,
                razonsocial: this.state.bussinesName,
                limite_mensual: this.state.limMonth,
                codigo: this.state.code,
                habilitado: this.state.enable,
                precio_hora: this.state.priceHour
            }
        }
        const response = await putEmpresa(put, header, this.props.match.params.id);
        if (response.status === 200) {
            this.props.history.push("/empresas")
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    render() {
        return (
            <div className="container">
                <Form onSubmit={this.handleClickButton}>
                    <div className="d-flex">
                        <h4>Editar empresa</h4>
                        <Loader
                            visible={this.state.showLoader}
                            className="ml-3"
                            type="Oval"
                            color="blue"
                            height={25}
                            width={25}
                        />
                    </div>
                    <hr className="mt-2" />
                    <Form.Row>
                        <Form.Group as={Col} >
                            <h6>Nombre *</h6>
                            <Form.Control
                                name="name"
                                onChange={this.handleInputChange}
                                defaultValue={this.state.name}
                                required
                                size="sm"
                                placeholder="Ingrese el nombre" />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Email *</h6>
                            <Form.Control
                                name="email"
                                defaultValue={this.state.email}
                                onChange={this.handleInputChange}
                                required
                                size="sm"
                                placeholder="Ingrese el email de la empresa" />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <h6>Dirección *</h6>
                            <Form.Control
                                name="address"
                                defaultValue={this.state.address}
                                onChange={this.handleInputChange}
                                required
                                size="sm"
                                placeholder="Ingrese una dirección" />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Contacto *</h6>
                            <Form.Control
                                name="contact"
                                defaultValue={this.state.contact}
                                onChange={this.handleInputChange}
                                required
                                size="sm"
                                placeholder="Ingrese un número de contacto" />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <h6>Pais *</h6>
                            <Form.Control
                                name="country"
                                defaultValue={this.state.country}
                                onChange={this.handleInputChange}
                                required
                                size="sm"
                                placeholder="Ingrese un pais" />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Ciudad *</h6>
                            <Form.Control
                                name="city"
                                defaultValue={this.state.city}
                                onChange={this.handleInputChange}
                                required
                                size="sm"
                                placeholder="Ingrese una ciudad" />
                        </Form.Group>
                    </Form.Row>


                    <Form.Row>
                        <Form.Group as={Col}>
                            <h6>Razón social *</h6>
                            <Form.Control
                                defaultValue={this.state.bussinesName}
                                name="bussinesName"
                                onChange={this.handleInputChange}
                                required
                                size="sm"
                                placeholder="Ingrese el RUC de la empresa" />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Precio viajes por hora *</h6>
                            <Form.Control
                                defaultValue={this.state.priceHour}

                                type="number"
                                step="any"
                                name="priceHour"
                                onChange={this.handleInputChange}
                                required
                                size="sm"
                                placeholder="Ingrese el precio por hora" />
                        </Form.Group>
                    </Form.Row>

                    <Form.Group >
                        <h6>Limite mensual *</h6>
                        <Form.Control
                            name="limMonth"
                            defaultValue={this.state.limMonth}
                            onChange={this.handleInputChange}
                            required
                            type="number"
                            step="any"
                            size="sm"
                            placeholder="Ingrese el limite mensual de la empresa" />
                    </Form.Group>

                    <Form.Group >
                        <h6>Codigo * </h6>
                        <Form.Control
                            name="code"
                            defaultValue={this.state.code}
                            onChange={this.handleInputChange}
                            required
                            type="number"
                            size="sm"
                            placeholder="Ingrese el codigo de la empresa" />
                    </Form.Group>

                    <Button type="submit" className="mt-3 mr-3" size="sm" variant="primary">
                        Actualizar
                    </Button>

                    <Button className="mt-3" size="sm" variant="secondary" onClick={() => this.props.history.push("/empresas")}>
                        Cancelar
                    </Button>
                </Form>
            </div>
        );
    }
}

export default EditarEmpresa;