import React from 'react';
import { Button } from "react-bootstrap"
import { FaDownload } from "react-icons/fa"

function CsvButton(props) {
  const handleClick = () => {
    props.onExport();
  };

  return (
    <div>
      <Button size="sm" className="btn btn-success" onClick={handleClick}><FaDownload /> Descargar CSV</Button>
    </div>
  );
};

export default CsvButton;