import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
//import Analisis from "./components/routes/Analisis";
import Perfil from "./components/routes/perfil/Perfil";
import Usuarios from "./components/routes/usuarios/Usuarios";
import CrearUsuario from "./components/routes/usuarios/CrearUsuario";
import Departamentos from "./components/routes/departamentos/Departamentos";
import CrearDepartamento from "./components/routes/departamentos/CrearDepartamento";
import AsociarDepartamento from "./components/routes/departamentos/AsociarDepartamento";
import EditarDepartamento from "./components/routes/departamentos/EditarDepartamento";
import Empresas from "./components/routes/empresas/Empresas";
import CrearEmpresa from "./components/routes/empresas/CrearEmpresa";
import EditarEmpresa from "./components/routes/empresas/EditarEmpresa";
//import Taxis from "./components/routes/taxis/Taxis";
//import Taxi from "./components/routes/taxis/Taxi";
import Solicitudes from "./components/routes/solicitudes/Solicitudes";
import Solicitud from "./components/routes/solicitudes/Solicitud";
import Header from "./components/routes/Header";
//import Dashboard from "./components/routes/Dashboard";
import Login from "./components/routes/Login";
import Usuario from "./components/routes/usuarios/Usuario";
import EditarUsuario from "./components/routes/usuarios/EditarUsuario";
import Administradores from "./components/routes/administradores/Administradores";
import CrearAdministrador from "./components/routes/administradores/CrearAdministrador";
import EditarAdministrador from "./components/routes/administradores/EditarAdministrador";
import PagarSolicitud from './components/routes/solicitudes/PagarSolicitud';
import ReporteMes from './components/routes/reportes/ReporteMes';
import ReporteUsuarioLimMes from './components/routes/reportes/ReporteUsuarioLimMes';
import ReporteTopUsuario from './components/routes/reportes/ReporteTopUsuario';
import ReporteDepartamento from './components/routes/reportes/ReporteDepartamento';
import Dashboard from './components/routes/Dashboard';
import './App.css';

/**
 * Para heroku
 * 
 *     //"dev": "react-scripts start",
    //"start": "serve -s build",
    //"build": "react-scripts build",
    //"test": "react-scripts test --env=jsdom",
    //"eject": "react-scripts eject",
    //"heroku-postbuild": "npm run build"
 */

function App() {
  return (
    <Router basename="react">
      <Route component={Header} />
      <Switch>
        <div className="mt-5 mb-5">
          <Route exact path="/" component={Login} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/solicitudes" component={Solicitudes} />
          <PrivateRoute exact path="/solicitudes/:id" component={Solicitud} />
          <PrivateRoute exact path="/pagar" component={PagarSolicitud} />
          <PrivateRoute exact path="/perfil/:id" component={Perfil} />
          <PrivateRoute exact path="/empresas" component={Empresas} />
          <PrivateRoute exact path="/empresas/crear" component={CrearEmpresa} />
          <PrivateRoute path="/empresas/editar/:id" component={EditarEmpresa} />
          <PrivateRoute exact path="/departamentos" component={Departamentos} />
          <PrivateRoute exact path="/departamentos/crear" component={CrearDepartamento} />
          <PrivateRoute path="/departamentos/asociar" component={AsociarDepartamento} />
          <PrivateRoute path="/departamentos/editar/:id" component={EditarDepartamento} />
          <PrivateRoute exact path="/usuarios" component={Usuarios} />
          <PrivateRoute exact path="/usuarios/usuario/:id" component={Usuario} />
          <PrivateRoute exact path="/usuarios/editar/:id" component={EditarUsuario} />
          <PrivateRoute exact path="/usuarios/crear" component={CrearUsuario} />
          <PrivateRoute exact path="/admins" component={Administradores} />
          <PrivateRoute exact path="/admins/crear" component={CrearAdministrador} />
          <PrivateRoute exact path="/admins/editar/:id" component={EditarAdministrador} />
          <PrivateRoute exact path="/reportes/solicitud" component={ReporteMes} />
          <PrivateRoute exact path="/reportes/usuarioLimMes" component={ReporteUsuarioLimMes} />
          <PrivateRoute exact path="/reportes/topUsuario" component={ReporteTopUsuario} />
          <PrivateRoute exact path="/reportes/departamento" component={ReporteDepartamento} />
        </div>
      </Switch>
    </Router>
  );
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    localStorage.getItem("token") !== null
      ? <Component {...props} />
      : <Redirect to='/' />
  )} />
)

export default App;
