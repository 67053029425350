import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from "react-bootstrap/Col";
import { getEmpresas, getDepartamentos, postAdmin } from "../../../utils/petitions";
import Toast from 'react-bootstrap/Toast';
import Loader from 'react-loader-spinner';

class CrearAdministrador extends Component {

    state = {
        enterprises: [],
        departments: [],
        ci: "",
        name: "",
        email: "",
        lastName: "",
        password: "",
        confirmPasword: "",
        limMonth: "",
        limTravel: "",
        isGeneral: false,

        enterprise: "",
        department: "",
        toastShow: false,
        showLoader: false
    }

    handleInputChange = ({ target: { name, value } }) => {
        this.setState({ [name]: value })
    }

    handleClickButton = (e) => {
        e.preventDefault();
        this.postTheAdmin();
    }

    handleCheckBox = (e) => {
        this.setState({
            isGeneral: e.target.checked
        })
    }

    postTheAdmin = async () => {
        if (this.state.password === this.state.confirmPasword) {
            this.setState({
                showLoader: true
            })
            const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
            let post = {}
            if (this.state.isGeneral) {
                post = {
                    admin: {
                        ci: this.state.ci,
                        nombre: this.state.name,
                        apellido: this.state.lastName,
                        email: this.state.email,
                        password: this.state.password,
                        is_gral: this.state.isGeneral ? "1" : "0",
                    }
                }
            } else {
                post = {
                    admin: {
                        ci: this.state.ci,
                        nombre: this.state.name,
                        apellido: this.state.lastName,
                        email: this.state.email,
                        password: this.state.password,
                        limitemensual: this.state.limMonth,
                        limite_viaje: this.state.limMonth,
                        is_gral: this.state.isGeneral ? "1" : "0",
                        empresa: this.state.enterprise,
                        departamento_id: this.state.department,
                    }
                }
            }
            const response = await postAdmin(header, post)
            if (response.status === 200) {
                this.props.history.push("/admins")
            } else if (response.status === 401) {
                localStorage.clear();
                this.props.history.push("/")
            }
        } else {
            this.setState({
                toastShow: true
            })
        }
    }

    componentDidMount() {
        this.getTheEmpresas();
        this.getTheDepartamentos();
    }

    getTheEmpresas = async () => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getEmpresas(header);
        if (response.status === 200) {
            this.setState({
                enterprises: response.data.empresas,
                loading: false
            })
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    getTheDepartamentos = async () => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getDepartamentos(header);
        if (response.status === 200) {
            this.setState({
                departments: response.data.departamentos,
                loading: false
            })
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    render() {
        return (
            <div className="container">
                <Toast className="toast rounded " onClose={() => this.setState({ toastShow: false })} show={this.state.toastShow} delay={3000} autohide>
                    <Toast.Body className="bg-danger text-white rounded ">Las contraseñas ingresadas no coinciden</Toast.Body>
                </Toast>
                <Form onSubmit={this.handleClickButton}>
                    <div className="d-flex">
                        <h4>Crear administrador</h4>
                        <Loader
                            visible={this.state.showLoader}
                            className="ml-3"
                            type="Oval"
                            color="blue"
                            height={25}
                            width={25}
                        />
                    </div>
                    <hr className="mt-2" />

                    <Form.Row>
                        <Form.Group as={Col}>
                            <h6>Cedula *</h6>
                            <Form.Control name="ci" onChange={this.handleInputChange} required size="sm" placeholder="Ingrese su cedula" />
                        </Form.Group >
                        <Form.Group as={Col}>
                            <h6>Email *</h6>
                            <Form.Control name="email" onChange={this.handleInputChange} required size="sm" placeholder="Ingrese un correo electronico" />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} >
                            <h6>Nombre *</h6>
                            <Form.Control name="name" onChange={this.handleInputChange} required size="sm" placeholder="Ingrese el nombre" />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Apellido *</h6>
                            <Form.Control name="lastName" onChange={this.handleInputChange} required size="sm" placeholder="Ingrese el apellido" />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <h6>Contraseña *</h6>
                            <Form.Control type="password" name="password" onChange={this.handleInputChange} required size="sm" placeholder="Ingrese una contraseña" />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Confirmar contraseña *</h6>
                            <Form.Control type="password" name="confirmPasword" onChange={this.handleInputChange} required size="sm" placeholder="Ingrese la contraseña nuevamente" />
                        </Form.Group>
                    </Form.Row>
                    {
                        !this.state.isGeneral ?
                            <div>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <h6>Limite mensual *</h6>
                                        <Form.Control name="limMonth" onChange={this.handleInputChange} required type="number" size="sm" placeholder="Ingrese el limite mensual" />
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <h6>Limite por viaje * </h6>
                                        <Form.Control name="limTravel" onChange={this.handleInputChange} required type="number" size="sm" placeholder="Ingrese el limite por viaje" />
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <h6>Empresa *</h6>
                                        <Form.Control onChange={this.handleInputChange} size="sm" as="select" name="enterprise">
                                            <option key={0} value={""}>Seleccione una empresa</option>
                                            {
                                                this.state.enterprises.map(item => (
                                                    <option key={item.id} value={item.numero}>{item.nombre}</option>
                                                ))
                                            }
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <h6>Departamento *</h6>
                                        <Form.Control onChange={this.handleInputChange} name="department" size="sm" as="select">
                                            <option key={0} value={""}>Seleccione un departamento</option>
                                            {
                                                this.state.departments.map(item => (
                                                    <option key={item.id} value={item.id}>{item.descripcion}</option>
                                                ))
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                            </div>
                            : null
                    }

                    <Form.Group checked={this.state.isGeneral} onChange={this.handleCheckBox}>
                        <Form.Check type="checkbox" label="Administrador general" />
                    </Form.Group>

                    <Button className="mt-3 mr-3" size="sm" variant="primary" type="submit">
                        Crear administrador
                    </Button>

                    <Button className="mt-3" size="sm" variant="secondary" onClick={() => this.props.history.push("/admins")}>
                        Cancelar
                    </Button>
                </Form>
            </div>
        );
    }
}

export default CrearAdministrador;