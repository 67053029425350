import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Link } from "react-router-dom";
import { FaPencilAlt } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { BiShow } from "react-icons/bi";
import { HiUserAdd } from "react-icons/hi";
import { getEmpresas, putEmpresa } from "../../../utils/petitions";
import Loader from 'react-loader-spinner';
import { FiXCircle } from "react-icons/fi";
import Toast from 'react-bootstrap/Toast';

class Empresas extends Component {

    state = {
        enterprises: [],
        loading: true,
        toastShow: false
    }

    getTheEmpresas = async () => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getEmpresas(header);
        if (response.status === 200) {
            this.setState({
                enterprises: response.data.empresas,
                loading: false
            })
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    componentDidMount() {
        this.getTheEmpresas();
    }

    setToggleStatusState = (id, itemToAdd) => {
        const response = this.state.enterprises.filter(item => item.id !== id);
        const enterprises = [...response, itemToAdd]
        this.setState({
            enterprises,
            toastShow: true
        })
    }

    toggleStatus = async (item) => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        let put = {
            empresa: {
                ...item,
                habilitado: item.habilitado === "1" ? "0" : "1"
            }
        }
        const response = await putEmpresa(put, header, item.numero);
        if (response.status === 200) {
            this.setToggleStatusState(item.id, put.empresa)
            //window.location.reload(false);
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }

    }

    render() {
        return (
            <div className="container">
                {this.state.loading ?
                    <div>
                        <Loader
                            className="centered"
                            type="Circles"
                            color="#000"
                            visible={this.state.loading}
                            height={50}
                            width={50}
                        />
                        <h6 className="centeredTwo">Cargando...</h6>
                    </div>
                    :
                    <div>
                        <Toast className="toast rounded " onClose={() => this.setState({ toastShow: false })} show={this.state.toastShow} delay={3000} autohide>
                            <Toast.Body className="bg-success text-white rounded ">Se cambio el estado de la empresa correctamente</Toast.Body>
                        </Toast>
                        <div className="d-flex">
                            <h4>Empresas</h4>
                            <Link style={{ color: "#fff" }} to={`/empresas/crear`}>
                                <Button size="sm" className="ml-3" >
                                    Nuevo
                                <HiUserAdd />
                                </Button>
                            </Link>
                        </div>
                        <Table responsive className="mt-3">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Limite mensual</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.enterprises !== undefined ?
                                    this.state.enterprises.map(elemento => (
                                        <tr key={elemento.id}>
                                            <td>{elemento.nombre}</td>
                                            <td>{elemento.limite_mensual}</td>
                                            <td>
                                                <Button size="sm" className="mr-3 btn btn-success rounded-pill">
                                                    Ver
                                                <BiShow className="actions" onClick={() => this.submit(elemento.id)} style={{ height: 20, width: 30, marginLeft: 10 }} />
                                                </Button>

                                                <Link style={{ color: "#fff" }} to={`/empresas/editar/${elemento.numero}`}>
                                                    <Button size="sm" className="mr-2 rounded-pill" >
                                                        Editar
                                                    <FaPencilAlt className="actions" style={{ height: 18, width: 30, marginLeft: 5 }} />
                                                    </Button>
                                                </Link>

                                                {elemento.habilitado === "1" ?
                                                    <Button onClick={() => this.toggleStatus(elemento)} size="sm" className="btn btn-danger rounded-pill">
                                                        Deshabilitar
                                                        <MdDeleteForever className="actions" style={{ height: 20, width: 30 }} />
                                                    </Button>
                                                    :
                                                    <Button onClick={() => this.toggleStatus(elemento)} size="sm" className="btn btn-secondary rounded-pill">
                                                        Habilitar
                                                    <FiXCircle className="actions" style={{ height: 20, width: 40 }} />
                                                    </Button>
                                                }

                                            </td>
                                        </tr>
                                    ))
                                    : ""
                                }
                            </tbody>
                        </Table>
                    </div>
                }
            </div>
        );
    }
}

export default Empresas;
