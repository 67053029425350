import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from "react-bootstrap/Col";
import { getUsuario, putUsuario, getDepartamentos, getEmpresas } from "../../../utils/petitions";
import Loader from 'react-loader-spinner';
import HiddenAlert from "../../components/HiddenAlert";

class EditarUsuario extends Component {

    state = {
        departments: [],
        enterprises: [],
        showLoader: false,
        name: "",
        lastname: "",
        identification: "",
        email_voyentaxi: "",
        email2: "",
        email: "",
        password: "",
        limMonth: "",
        limTravel: "",
        department: "",
        isEnable: "",
        enterprise: "",
        error: false,
        errorText: "",
        pin: "",
    }

    componentDidMount() {
        this.getTheUsuario();
        this.getTheDepartamentos();
        this.getTheEmpresas();
    }

    setDefaultItems = () => {
        this.setState({
            showLoader: false,
            error: false,
            errorText: false,

        })
    }

    getTheEmpresas = async () => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getEmpresas(header);
        if (response.status === 200) {
            this.setState({
                enterprises: response.data.empresas,
                loading: false
            })
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    getTheDepartamentos = async () => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getDepartamentos(header);
        if (response.status === 200) {
            this.setState({
                departments: response.data.departamentos,
                loading: false
            })
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    putTheUsuario = async () => {
        this.setDefaultItems();
        this.setState({
            showLoader: true
        })
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const put = {
            usuario: {
                nombre: this.state.name,
                apellido: this.state.lastname,
                telefono: this.state.email2,
                ci: this.state.identification.trim(),
                email_voyentaxi: this.state.email_voyentaxi,
                email: this.state.email,
                password: this.state.password,
                limite_mensual: this.state.limMonth, //?
                limite_viaje: this.state.limTravel,
                limitemensual: this.state.limMonth, // ?
                habilitado: this.state.isEnable ? 1 : 0,
                departamento_id: this.state.department,
                num_empresa: this.state.enterprise,
                pin: this.state.pin.trim()
            }
        }
        const response = await putUsuario(header, this.props.match.params.id, put)
        if (response) {
            if (response.status === 200) {
                this.props.history.push("/usuarios")
            } else if (response.status === 401) {
                localStorage.clear();
                this.props.history.push("/")
            } else {
                this.setState({
                    error: true,
                    errorText: response.data.errors.msj
                })
            }
            this.setState({ showLoader: false })
        }
    }

    getTheUsuario = async () => {
        const header = `token=${localStorage.getItem("token")}, email=${localStorage.getItem("email")}`
        const response = await getUsuario(header, this.props.match.params.id);
        console.log(response)
        if (response.status === 200) {
            this.setState({
                name: response.data.usuario.nombre,
                lastname: response.data.usuario.apellido,
                identification: response.data.usuario.ci,
                email2: response.data.usuario.telefono,
                email: response.data.usuario.email,
                email_voyentaxi: response.data.usuario.email_voyentaxi,
                password: "",
                limMonth: response.data.usuario.limitemensual,
                limTravel: response.data.usuario.limite_viaje,
                department: response.data.usuario.departamento_id,
                enterprise: response.data.usuario.empresa_id,
                isEnable: response.data.usuario.habilitado === 1 ? true : false
            })
        } else if (response.status === 401) {
            localStorage.clear();
            this.props.history.push("/")
        }
    }

    handleInputChange = ({ target: { name, value } }) => {
        this.setState({ [name]: value })
    }

    handleCheckBox = (e) => {
        this.setState({
            isEnable: e.target.checked
        })
    }

    handleClickButton = (e) => {
        this.putTheUsuario();
        e.preventDefault();
    }

    render() {
        return (
            <div className="container" >
                <Form onSubmit={this.handleClickButton}>
                    <div className="d-flex">
                        <h4>Editar usuario</h4>
                        <Loader
                            visible={this.state.showLoader}
                            className="ml-3"
                            type="Oval"
                            color="blue"
                            height={25}
                            width={25}
                        />
                    </div>
                    <hr className="mt-2" />
                    <Form.Row>
                        <Form.Group as={Col} >
                            <h6>Nombre *</h6>
                            <Form.Control
                                name="name"
                                value={this.state.name}
                                onChange={this.handleInputChange}
                                required
                                size="sm"
                                placeholder="Ingrese el nombre del usuario" />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Apellido *</h6>
                            <Form.Control
                                name="lastname"
                                value={this.state.lastname}
                                onChange={this.handleInputChange}
                                required
                                size="sm"
                                placeholder="Ingrese un apellido" />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <h6>Email *</h6>
                            <Form.Control
                                name="email"
                                value={this.state.email}
                                onChange={this.handleInputChange}
                                required
                                size="sm"
                                placeholder="Ingrese el email del usuario" />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Email encargado</h6>
                            <Form.Control
                                name="email2"
                                value={this.state.email2}
                                onChange={this.handleInputChange}
                                size="sm"
                                placeholder="Ingrese el email encargado" />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} >
                            <h6>CI *</h6>
                            <Form.Control
                                value={this.state.identification}
                                name="identification"
                                onChange={this.handleInputChange}
                                required
                                size="sm"
                                placeholder="Ingrese un nro de documento del usuario" />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Email vet</h6>
                            <Form.Control
                                name="email_voyentaxi"
                                value={this.state.email_voyentaxi}
                                onChange={this.handleInputChange}
                                size="sm"
                                placeholder="Ingrese el email vet" />
                        </Form.Group>

                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <h6>Contraseña *</h6>
                            <Form.Control
                                name="password"
                                onChange={this.handleInputChange}
                                type="password"
                                size="sm"
                                placeholder="Ingrese la contraseña del usuario o deje en blanco" />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Pin corporativo *</h6>
                            <Form.Control
                                name="pin"
                                onChange={this.handleInputChange}
                                size="sm"
                                placeholder="Ingrese el pin corporativo o deje en blanco" />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <h6>Limite mensual *</h6>
                            <Form.Control
                                defaultValue={this.state.limMonth}
                                name="limMonth"
                                onChange={this.handleInputChange}
                                type="number"
                                required
                                size="sm"
                                placeholder="Ingrese el limite mensual en $" />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <h6>Limite por viaje *</h6>
                            <Form.Control
                                defaultValue={this.state.limTravel}
                                name="limTravel"
                                onChange={this.handleInputChange}
                                type="number"
                                required
                                size="sm"
                                placeholder="Ingrese el limite por viaje en $" />
                        </Form.Group>
                    </Form.Row>

                    <Form.Group>
                        <h6>Empresa *</h6>
                        <Form.Control name="enterprise" onChange={this.handleInputChange} value={this.state.enterprise} size="sm" as="select">
                            <option key={0} value="">Seleccione una empresa</option>
                            {
                                this.state.enterprises.map(item => (
                                    <option key={item.id} value={item.numero}>{item.nombre}</option>
                                ))
                            }
                        </Form.Control>
                    </Form.Group>


                    <Form.Group>
                        <h6>Departamento *</h6>
                        <Form.Control
                            name="department"
                            value={this.state.department}
                            onChange={this.handleInputChange}
                            size="sm"
                            as="select"
                            required
                        >
                            <option key={0} value={""}>Seleccione un departamento</option>
                            {
                                this.state.departments.map(item => (
                                    <option key={item.id} value={item.id}>{item.descripcion}</option>
                                ))
                            }
                        </Form.Control>
                    </Form.Group>

                    <Form.Group>
                        <Form.Check checked={this.state.isEnable} name="isEnable" onChange={this.handleCheckBox} type="checkbox" label="Habilitado" />
                    </Form.Group>

                    <Button className="mt-3 mr-3" size="sm" variant="primary" type="submit">
                        Editar usuario
                    </Button>

                    <Button className="mt-3" size="sm" variant="secondary" onClick={() => this.props.history.push("/usuarios")}>
                        Cancelar
                    </Button>
                </Form>
                <div className="d-flex justify-content-center mt-2">
                    <HiddenAlert visible={this.state.error} text={this.state.errorText} variant="danger" />
                </div>
            </div>
        )
    }
}

export default EditarUsuario;